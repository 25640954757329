var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"livedemo"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header_right"},[_c('router-link',{attrs:{"to":'/'}},[_c('img',{attrs:{"src":require("../assets/image/index_nav-1(2).png"),"alt":""}})])],1),_c('div',{staticClass:"header_left"},[_c('WorkHeader',{on:{"getShow":_vm.UserShow,"getMessageShow":_vm.MessageShow}})],1)]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"main_left"},[_c('div',{staticClass:"gotoWork",attrs:{"name":"gotoWorkbench"}},[_c('router-link',{attrs:{"to":"/Workbench?workID=1"}},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v("工作台")])])],1),_c('router-link',{attrs:{"to":"/livedemo/livefunlist"}},[_c('a',{class:{ cur: _vm.curId === 0 },on:{"click":function($event){(_vm.curId = 0),
              (_vm.Show.myUserInfo = false),
              (_vm.Show.mainShow = true),
              (_vm.Show.messageShow = false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.curId === 1),expression:"curId === 1"}],attrs:{"src":require("../assets/image/live_template.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.curId === 0),expression:"curId === 0"}],attrs:{"src":require("../assets/image/live_template_select.png"),"alt":""}}),_vm._v(" 写作模板 ")])]),_c('router-link',{attrs:{"to":"/livedemo/livemywoke"}},[_c('a',{class:{ cur: _vm.curId === 1 },on:{"click":function($event){(_vm.curId = 1),
              (_vm.Show.myUserInfo = false),
              (_vm.Show.mainShow = true),
              (_vm.Show.messageShow = false)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.curId === 0),expression:"curId === 0"}],attrs:{"src":require("../assets/image/live_work.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.curId === 1),expression:"curId === 1"}],attrs:{"src":require("../assets/image/live_work_select.png"),"alt":""}}),_vm._v(" 作品管理 ")])])],1),_c('div',{staticClass:"main_right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.inp_show),expression:"this.inp_show"}],staticClass:"main_search"},[_c('el-input',{staticClass:"search",attrs:{"placeholder":"搜索关键词查询"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})]),_vm._m(0)],1),_c('router-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search_btn"},[_c('span',[_vm._v("搜索")])])
}]

export { render, staticRenderFns }