<template>
  <div class="membercenter">
    <div class="mem_header">
      <div class="left">
        <router-link :to="'/'">
          <img src="../assets/image/index_nav-1(2).png" alt="" />
        </router-link>
      </div>
      <div class="mid">
        <ul>
          <li :class="{ cur: curId === 0 }">
            <a href="javascript:;" @click="curId = 0">会员套餐</a>
            <br />
            <span></span>
          </li>
          <!-- <li :class="{ cur: curId === 1 }">
            <a href="javascript:;" @click="curId = 1">卖卡赚钱</a>
            <br />
            <span></span>
          </li> -->
          <li :class="{ cur: curId === 2 }">
            <a href="javascript:;" @click="curId = 2">激活码兑换</a>
            <br />
            <span></span>
          </li>
        </ul>
      </div>
      <div class="right">
        <WorkHeader></WorkHeader>
      </div>
    </div>
    <!-- 会员套餐 -->
    <div class="mem_package" v-show="curId == 0">
      <p class="title">加入书营大家庭，让创作更高效！</p>
      <p class="p_sp">根据您的团队与业务需求选择相应套餐</p>
      <div class="memList">
        <div class="mem_item one">
          <p class="item_title">个人版</p>
          <p class="item_sp">适合小微团队或个人</p>
          <p class="border"></p>
          <ul>
            <li>无限生成次数</li>
            <li>生成速度加速</li>
            <li>全站模版任意使用</li>
            <li>一次批量生成5条</li>
            <li>定期免费更新语料</li>
            <li>无广纯享版</li>
            <li>云储存</li>
          </ul>
          <div class="item_btn">
            <p class="originPrice">
              <!-- ￥9.9 -->
            </p>
            <p class="price">
              ￥&ensp;
              <b>{{ Math.floor(this.infoList[0].price_person_year) / 365 }}</b>
              <i>
                {{
                  (
                    (
                      this.infoList[0].price_person_year -
                      Math.floor(this.infoList[0].price_person_year)
                    ).toFixed(1) + ""
                  ).substr(1)
                }}</i
              >

              &ensp;
              <b style="font-size: 1.5625rem; font-weight: 500">/天</b>&emsp;
              <b
                style="
                  font-weight: 400;
                  font-size: 0.875rem;
                  color: rgba(128, 128, 128, 0.658);
                  transform: scale(0.2);
                "
                >起</b
              >
            </p>
            <el-button
              class="p_btn"
              @click="getmember(((member_preson = true), (grade = 1)))"
            >
              月付
            </el-button>
          </div>
        </div>
        <div class="mem_item two">
          <p class="item_title">初创团队版</p>
          <p class="item_sp">适合创业公司</p>
          <p class="border"></p>
          <ul>
            <li>无限生成次数</li>
            <li>生成速度加速</li>
            <li>全站模版任意使用</li>
            <li>一次批量生成5条</li>
            <li>定期免费更新语料</li>
            <li>无广纯享版</li>
            <li>云储存</li>
            <li>升级更高模型+专属客服</li>
            <li>支持8台设备同时在线</li>
          </ul>
          <div class="item_btn">
            <p class="originPrice">
              <!-- ￥238 -->
            </p>
            <p class="price">
              ￥&ensp;
              <b>{{ Math.floor(this.infoList[1].price_firm_year / 365) }}</b>
              <i>
                {{
                  (
                    (this.infoList[1].price_firm_year / 365).toFixed(1) -
                    Math.floor(this.infoList[1].price_firm_year / 365)
                  )
                    .toFixed(1)
                    .substr(1)
                }}</i
              >
              &ensp;
              <b style="font-size: 1.5625rem; font-weight: 500">/天</b>&emsp;
              <b
                style="
                  font-weight: 400;
                  font-size: 0.875rem;
                  color: rgba(128, 128, 128, 0.658);
                "
                >起</b
              >
            </p>
            <el-button
              class="p_btn"
              @click="getmember(((member_firm = true), (grade = 5)))"
            >
              季付
            </el-button>
          </div>
        </div>
        <div class="mem_item three">
          <p class="item_title">企业版</p>
          <p class="item_sp">适合大型团队或企业</p>
          <p class="border"></p>
          <ul>
            <li>按需定制</li>
            <!-- <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li> -->
          </ul>
          <div class="item_btn">
            <p class="originPrice">
              <!-- ￥3 -->
            </p>
            <p class="price"></p>
            <el-button class="p_btn" @click="getconsult">获取报价</el-button>
          </div>
        </div>
        <div class="mem_item four">
          <p class="item_title">企业内网部署版</p>
          <p class="item_sp">适合有企业内网部署的需求</p>
          <p class="border"></p>
          <ul>
            <li>按需定制</li>
            <!-- <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li>
            <li>这是一条权益</li> -->
          </ul>
          <div class="item_btn">
            <p class="originPrice">
              <!-- ￥3 -->
            </p>
            <p class="price" style="color: #fff">
              ￥&ensp;
              <b>{{ this.price1.substr(0, this.price1.length - 2) }}</b>
              {{ this.price1.match(/.(\S*)/)[1] }}
              &ensp;/天
            </p>
            <el-button class="p_btn" @click="getconsult">获取报价</el-button>
          </div>
        </div>
      </div>
      <div class="more_fun">
        <p>更多服务</p>
        <div class="more_fun_list">
          <div class="list_one">
            <p>
              <b>￥</b>
              99
            </p>
            <div class="pay">立即购买</div>
          </div>
          <div class="list_two">
            <p>
              <b>￥</b>
              99
            </p>
            <div class="pay">立即购买</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 卖卡挣钱 -->
    <div class="men_makemon" v-show="curId == 1">
      <div class="make_header make_item">
        <img src="../assets/image/makemon/Banner.png" alt="" />
      </div>
      <div class="make_flow make_item">
        <p class="p_title">
          <b>/</b>
          返佣流程
        </p>
      </div>
      <div class="make_rule make_item">
        <p class="p_title">
          <b>/</b>
          返佣规则
        </p>
        <p class="rule_title">成为我们的会员就可以卖卡赚钱啦！</p>
        <p class="rule_sp">
          成功加入书营大家庭成为我们的会员就可以卖卡赚钱啦，其它用户通过您发出的分享链接，也成为我们的会员，您即可获得现金红包返现。
          红包最高可得 <b>99</b> 元
        </p>
        <div class="rlue_list">
          <div>
            <li>邀请人数</li>
            <li>月卡</li>
            <li>季卡</li>
            <li>年卡</li>
            <li>部署版</li>
          </div>
          <div>
            <li>1~2人</li>
            <li>/</li>
            <li>￥20</li>
            <li>￥50</li>
            <li>
              ￥99
              <span>最高</span>
            </li>
          </div>
          <div>
            <li>3~5人</li>
            <li>/</li>
            <li>￥10</li>
            <li>￥25</li>
            <li>
              ￥99
              <span>最高</span>
            </li>
          </div>
          <div>
            <li>5人以上</li>
            <li>/</li>
            <li>￥10</li>
            <li>￥25</li>
            <li>
              ￥99
              <span>最高</span>
            </li>
          </div>
        </div>
      </div>
      <div class="make_course make_item">
        <p class="p_title">
          <b>/</b>
          返佣教程
          <span>Tips:返佣请到app提取,PC端暂不支持,谢谢配合!</span>
        </p>
        <div class="course_way">
          <div>
            <img src="../assets/image/makemon/makemom_image_01.png" alt="" />
            <p>
              <b>01</b>
              身份验证
            </p>
            <span>
              成为会员后，使用手机扫描页面中提示的二维码，点击页面中的【我的收益】按钮，跳转后完成个人身份信息实名认证
            </span>
          </div>
          <div>
            <img src="../assets/image/makemon/arrow.png" alt="" />
          </div>
          <div>
            <img src="../assets/image/makemon/makemom_image_02.png" alt="" />
            <p>
              <b>02</b>
              收益提现
            </p>
            <span>
              按自然月周期进行结算。期间产生的收益介绍周期为30天，第32天完成个税清算及最终金额核算。之后，您可以自主进行提现，在【我的收益】里进行提现
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--激活码兑换  -->
    <div class="men_keycode" v-show="curId == 2">
      <div class="keyCode_header">
        <p class="p_title">激活码兑换</p>
        <p class="p_sp">您好，<b>{{  this.userInfo.nickName}}</b> ，欢迎使用激活码兑换！</p>
      </div>
      <div class="keyCode_inp">
        <div class="key_code">
          <span>激活码</span>
          <input
            maxlength="39"
            placeholder="请输入32位激活码，字母不区分大小写"
            v-model="code_key"
            @input="formatCardNumber"
          />
        </div>
        <div class="key_code">
          <p class="p_phone_key">
            将给手机号为{{ this.userInfo_phone }}的用户发送验证码
          </p>
        </div>
        <div class="ven_code">
          <span>验证码</span>
          <input type="text" v-model="code_ven" placeholder="请输入验证码" />
          <p class="p_code" v-show="!key_codeShow" @click="getCodekey">
            {{ this.key_codeText }}
          </p>
          <p class="p_code" v-show="key_codeShow">
            {{ this.key_time }}{{ this.key_hintText }}
          </p>
        </div>
      </div>
      <div class="keyCode_btn">
        <el-button class="activate" :plain="true" @click="codeBtn"
          >立即激活</el-button
        >
        <!-- <button class="queryTime">查询时长</button> -->
      </div>
      <div class="keyCode_info">
        <div class="info_header">
          <p class="p_title">
            <b>/</b>
            激活码使用说明
          </p>
        </div>
        <div class="info_main">
          <ul>
            <li>1. 请在有效期内激活，逾期激活码将失效；</li>
            <li>
              2.
              会员有效期从激活日算起，同一帐号使用多个激活码，会员期自动延长；
            </li>
            <li>
              3. 激活成功后，即刻享有书营AI会员服务，查看
              <!-- <router-link
                target="_blank"
                :to="'/showwin_agrement/showwin_agrement_privacy'"
              > -->
              <span @click="lookMem">会员特权</span>
              <!-- </router-link> -->
            </li>
            <li>
              4. 在法律允许的范围内，本公司享有解释权，如有疑问，请
              <span @click="call">联系客服</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 会员套餐遮罩层 -->
    <div class="maskLayer" v-show="showLayer">
      <div class="consult" v-show="consult">
        <div class="consult_header">
          <p class="p_title">获取报价</p>
          <p class="p_icon">
            <i class="el-icon-close" @click="close"></i>
          </p>
        </div>
        <div class="consult_main">
          <div class="header_photo">
            <p class="p_photo"></p>
          </div>
          <div class="p_sp">
            <p>
              亲爱的 <b>{{ this.userInfo.nickName }}</b> 用户
            </p>
            <p>具体报价请扫码添加客服咨询~</p>
          </div>
          <div class="code">
            <p class="p_code">
              <img src="../assets/image/QRcode/service.png" alt="" />
            </p>
            <p class="p_sp">微信扫码添加客服</p>
          </div>
          <div class="p_footer">
            <p>欢迎加入书营的大家庭，让创作更高效！</p>
          </div>
        </div>
      </div>
      <div class="member" v-show="member">
        <div class="member_header">
          <p class="p_title">会员支付</p>
          <p class="p_icon">
            <i class="el-icon-close" @click="close"></i>
          </p>
        </div>
        <div class="member_main">
          <div class="header_title">
            <p class="p_photo">
              <img :src="this.userInfo.avatarUrl" alt="" />
            </p>
            <p class="p_sp">
              <span
                >亲爱的
                <b>{{ this.userInfo.nickName }}</b>
                用户
                <i v-show="!userInfo.vipStatus">，您还不是我们的会员</i>
                <i v-show="userInfo.vipStatus">，欢迎续费会员服务</i></span
              >
              <span>欢迎加入书营的大家庭，让创作更高效！</span>
            </p>
          </div>
          <div class="main_list person" v-show="member_preson">
            <div
              class="mem_day menlist"
              :class="{ cur: grade === 1 }"
              @click="selMem((id = 1))"
            >
              <p class="info_goodsname info">日卡</p>
              <div class="info_goodsprice info">
                <p>￥{{ this.infoList[0].price_person_day }}</p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[0].originPrice_person_day }}
              </p>
              <p class="info_footer info">即刻体验</p>

              <!-- 
              <div class="info_goodsname info">
                <p>
                  <span
                    >￥{{ this.infoList[0].originPrice_person_day }}&nbsp;</span
                  >
                </p>
                <p>日卡</p>
              </div>
              <div class="info_price info">
                <span>
                  <i>￥</i>
                  <b>{{ this.infoList[0].price_person_day }}</b>
                </span>
              </div> -->
            </div>
            <div
              class="mem_month menlist"
              :class="{ cur: grade === 2 }"
              @click="selMem((id = 2))"
            >
              <p class="info_goodsname info">月卡</p>
              <div class="info_goodsprice info">
                <p>
                  <!-- <b></b> -->
                  ￥{{ this.infoList[0].price_person_month }}
                </p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[0].originPrice_person_month }}
              </p>
              <p class="info_footer info">
                <span>
                  仅{{
                    (this.infoList[0].price_person_month / 30).toFixed(1)
                  }}元一天
                </span>
              </p>
            </div>
            <div
              class="mem_season menlist"
              :class="{ cur: grade === 3 }"
              @click="selMem((id = 3))"
            >
              <p class="info_goodsname info">季卡</p>
              <div class="info_goodsprice info">
                <p>￥{{ this.infoList[0].price_person_season }}</p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[0].originPrice_person_season }}
              </p>
              <p class="info_footer info">
                <span>
                  仅{{
                    (this.infoList[0].price_person_season / 90).toFixed(1)
                  }}元一天
                </span>
              </p>
            </div>
            <div
              class="mem_year menlist"
              :class="{ cur: grade === 4 }"
              @click="selMem((id = 4))"
            >
              <p class="info_goodsname info">年卡</p>
              <div class="info_goodsprice info">
                <!-- <p>￥</p> -->
                <p>￥{{ this.infoList[0].price_person_year }}</p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[0].originPrice_person_year }}
              </p>
              <p class="info_footer info">
                <span>
                  仅{{
                    (this.infoList[0].price_person_year / 365).toFixed(1)
                  }}元一天
                </span>
              </p>

              <!-- <p class="p_info">
                <span>年卡</span>
                <br />
                <span>
                  <i>￥</i>
                  <b>{{ this.infoList[0].price_person_year }}</b>
                  /仅{{
                    (this.infoList[0].price_person_year / 365).toFixed(1)
                  }}元一天
                </span>
              </p> -->
            </div>
          </div>
          <div class="main_list firm" v-show="member_firm">
            <div
              class="mem_month menlist"
              :class="{ cur: grade === 5 }"
              @click="selMem((id = 5))"
            >
              <p class="info_goodsname info">半年卡</p>
              <div class="info_goodsprice info">
                <!-- <p>￥</p> -->
                <p>￥{{ this.infoList[1].price_firm_halfyear }}</p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[1].originPrice_firm_halfyear }}
              </p>
              <p class="info_footer info">
                <span>
                  仅{{
                    (this.infoList[1].price_firm_halfyear / 180).toFixed(1)
                  }}元一天
                </span>
              </p>

              <!-- <div class="info_goodsname info">
                <p>
                  <span
                    >￥{{
                      this.infoList[1].originPrice_firm_halfyear
                    }}&nbsp;</span
                  >
                </p>
                <p>年卡</p>
              </div>
              <div class="info_price info">
                <span>
                  <i>￥</i>
                  <b>{{ this.infoList[1].price_firm_halfyear }}</b>
                  /仅{{
                    (this.infoList[1].price_firm_halfyear / 182).toFixed(1)
                  }}元一天
                </span>
              </div> -->
            </div>
            <div
              class="mem_month menlist"
              :class="{ cur: grade === 6 }"
              @click="selMem((id = 6))"
            >
              <!-- <div class="info_goodsname info">
                <p>
                  <span>￥{{ this.infoList[1].originPrice_firm_year }}</span>
                </p>
                <p>年卡</p>
              </div>
              <div class="info_price info">
                <span>
                  <i>￥</i>
                  <b>{{ this.infoList[1].price_firm_year }}</b>
                  /仅{{
                    (this.infoList[1].price_firm_year / 365).toFixed(1)
                  }}元一天
                </span>
              </div> -->
              <p class="info_goodsname info">年卡</p>
              <div class="info_goodsprice info">
                <!-- <p>￥</p> -->
                <p>￥{{ this.infoList[1].price_firm_year }}</p>
              </div>
              <p class="info_goodsoriginPrice info">
                ￥{{ this.infoList[1].originPrice_firm_year }}
              </p>
              <p class="info_footer info">
                <span>
                  仅{{
                    (this.infoList[1].price_firm_year / 365).toFixed(1)
                  }}元一天
                </span>
              </p>
            </div>
          </div>
          <div class="main_pay">
            <!-- <div class="pay_left">
              <span
                class="code_month pay_code"
                style="width: 12.5rem; height: 12.5rem; display: inline-block"
              >
                <canvas id="QRCode"></canvas>
              </span>
            </div> -->
            <div class="pay_right">
              <div class="pay_header pay">
                <p>支持支付宝、微信和银联支付</p>
                <p>
                  <i class="el-icon-time"></i>
                  剩余支付时间:&ensp;{{
                    this.date_minutes + ":" + this.date_seconds
                  }}
                </p>
              </div>
              <div class="pay_clause pay">
                <!-- <el-checkbox v-model="checked" size="medium"> -->
                <span
                  >开通即同意
                  <router-link
                    class="route"
                    target="_blank"
                    :to="'/showwin_agrement/showwin_agrement_service'"
                  >
                    <b>《书营AI服务协议》</b></router-link
                  >
                </span>
                <!-- </el-checkbox> -->
              </div>
              <div class="pay_way pay">
                <p>支付方式:</p>
                <p class="way">
                  <el-radio size="medium" v-model="radio" label="1">
                    <img
                      src="../assets/image/member/member_payway_01.png"
                      alt=""
                    />支付宝
                  </el-radio>
                  <!-- <el-radio size="medium" v-model="radio" label="2">
                    <img src="../assets/image/微信.png" alt="" />微信</el-radio
                  >
                  <el-radio size="medium" v-model="radio" label="3">
                    <img src="../assets/image/银联.png" alt="" />银联</el-radio
                  > -->
                </p>
                <p class="should_pay">
                  <span
                    >您需支付 <b>{{ this.should_pay }}</b
                    >元</span
                  >
                </p>

                <div class="BtnPay">
                  <div class="gotopay" @click="GotoPay">立即支付</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <login @getShow="loginCh" :msg="Login"></login>
  </div>
</template>
<script>
/**
 * * 订单支付流程
 * *    用户点击订单 =>
 * *    后端生成链接 =>
 * *    前端生成二维码(此时交易并未生成) =>
 * *    点击商品显示二维码(第一个默认)，轮询当前显示的二维码的交易 =>
 * *    点击下一个商品，切换轮询
 * *        1.当前轮询检测支付成功，则重新请求用户信息(修改用户信息由后端完成)
 * *        2.非当前轮询支付成功，则 => 1).在关闭商品框时重新请求用户信息、
 * *                                  2).在修改路由 => 页面从 会员中心 跳出 => 请求用户信息并和原来的信息进行比对
 * *    退出ww
 */

import login from "../components/login.vue";
// import QRCode from "qrcode";
import moment from "moment";
import WorkHeader from "../components/workHeader.vue";
import {
  GetAllCategory,
  QueryCommodityByType,
  TradePagePay,
  GetCode,
  VerifyActivationCode,
  GetUserInfoById,
  // GetUserWorkByUserId,
  // GetUserInfoById,
} from "../request/api";
export default {
  components: { login, WorkHeader },
  name: "App",
  data() {
    return {
      // closeShow: false,
      Login: false,
      qrCodeShow: false,
      num: 0,
      userInfo: {},
      userInfo_phone: "",
      goodsInfo: [],
      //
      curId: 0,
      infoList: [
        {
          originPrice_person_day: "",
          price_person_day: "",
          originPrice_person_month: "",
          price_person_month: "",
          originPrice_person_season: "",
          price_person_season: "",
          originPrice_person_year: "",
          price_person_year: "",
        },
        {
          originPrice_firm_halfyear: "",
          price_firm_halfyear: "",
          originPrice_firm_year: "",
          price_firm_year: "",
        },
      ],

      goodsType: [],

      price1: "0",
      price1_1: "27",
      price2: "49",
      price2_2: "299",
      price3: "1999",

      //激活码  验证码
      key_timer: null,
      key_time: 60,
      key_codeText: "获取验证码",
      key_hintText: "秒后重新发送",
      key_codeShow: false,
      key_res_info: {
        event: "",
        tokenId: "",
      },

      code_key: "",
      code_ven: "",
      //遮罩层
      showLayer: false,
      consult: false,
      member: false,
      member_preson: false,
      member_firm: false,
      //支付等级
      grade: 0,
      //
      date: 5 * 1000 * 60,
      date_minutes: 0,
      date_seconds: 0,
      timer: null,
      checked: true,
      radio: "1",
      should_pay: null,

      //支付二维码格式
      opts: {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        // width: 160, //宽
        // height: 160, //高
        text: "https://qr.alipay.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      },

      //六个产品的六个订单号
      outTradeNo: {},

      //六个订单编号
      orderInfo: null,
      //三个二维码链接
      jsonArr: {
        person: {
          day: "",
          month: "",
          season: "",
          year: "",
        },
        firm: {
          halfyear: "",
          year: "",
        },
      },

      //二维码dom
      msg: {},
      //
      timer_pay: null,
      timer_revocation: null,
    };
  },
  mounted() {},

  methods: {
    //获取价格
    getPrice() {
      this.goodsInfo = JSON.parse(localStorage.getItem("goodsInfo"));
      // console.log(this.goodsInfo);
      //拿goodsInfo
      if (this.goodsInfo == null) {
        this.goodsInfo = [];
        GetAllCategory()
          .then((res) => {
            // console.log(res);
            if (res.code == 200) {
              res.result.map((item) => {
                if (item.name == "个人版" || item.name == "初创团队版") {
                  let list = {
                    name: item.name,
                    id: item.id,
                  };
                  this.goodsInfo.push(list);
                  // console.log("存到的goodsInfo");
                }
                this.goodsInfo.map((item) => {
                  let type = {
                    type: item.id,
                  };
                  QueryCommodityByType({ ...type }).then((res) => {
                    item.info = [...res.result];
                  });
                });
              });
            }
          })
          .then(() => {
            // console.log("初次进入页面");
            localStorage.setItem(
              "goodsInfo",
              JSON.stringify([...this.goodsInfo])
            );
          })
          .then(() => {
            this.goodsInfo.map((item) => {
              let type = {
                type: item.id,
              };
              QueryCommodityByType({ ...type }).then((res) => {
                item.info = [...res.result];

                switch (item.name) {
                  case "个人版":
                    // console.log(item.info);
                    item.info.map((i) => {
                      switch (i.name) {
                        case "日卡":
                          this.infoList[0].price_person_day = i.price;
                          this.infoList[0].originPrice_person_day =
                            i.originPrice;
                          this.goodsType[0] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                        case "月卡":
                          this.infoList[0].price_person_month = i.price;
                          this.infoList[0].originPrice_person_month =
                            i.originPrice;
                          this.goodsType[1] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                        case "季卡":
                          this.infoList[0].price_person_season = i.price;
                          this.infoList[0].originPrice_person_season =
                            i.originPrice;
                          this.goodsType[2] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                        case "年卡":
                          this.infoList[0].price_person_year = i.price;
                          this.infoList[0].originPrice_person_year =
                            i.originPrice;
                          this.goodsType[3] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                      }
                    });
                    break;
                  case "初创团队版":
                    // console.log(item.info);
                    item.info.map((i) => {
                      switch (i.name) {
                        case "半年卡":
                          this.infoList[1].originPrice_firm_halfyear =
                            i.originPrice;
                          this.infoList[1].price_firm_halfyear = i.price;
                          this.goodsType[4] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                        case "年卡":
                          this.infoList[1].originPrice_firm_year =
                            i.originPrice;
                          this.infoList[1].price_firm_year = i.price;
                          this.goodsType[5] = {
                            type: i.type,
                            typeId: i.id,
                            name: i.name,
                            id: i.id,
                          };
                          break;
                      }
                    });
                    break;
                }
              });
            });
          });
      } else {
        // console.log("二次进入页面");
        this.goodsInfo.map((item) => {
          let type = {
            type: item.id,
          };
          QueryCommodityByType({ ...type }).then((res) => {
            item.info = [...res.result];

            switch (item.name) {
              case "个人版":
                // console.log(item.info);
                item.info.map((i) => {
                  switch (i.name) {
                    case "日卡":
                      this.infoList[0].originPrice_person_day = i.originPrice;
                      this.infoList[0].price_person_day = i.price;
                      this.goodsType[0] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                    case "月卡":
                      this.infoList[0].price_person_month = i.price;
                      this.infoList[0].originPrice_person_month = i.originPrice;
                      this.goodsType[1] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                    case "季卡":
                      this.infoList[0].price_person_season = i.price;
                      this.infoList[0].originPrice_person_season =
                        i.originPrice;
                      this.goodsType[2] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                    case "年卡":
                      this.infoList[0].price_person_year = i.price;
                      this.infoList[0].originPrice_person_year = i.originPrice;
                      this.goodsType[3] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                  }
                });
                break;
              case "初创团队版":
                // console.log(item.info);
                item.info.map((i) => {
                  switch (i.name) {
                    case "半年卡":
                      this.infoList[1].price_firm_halfyear = i.price;
                      this.infoList[1].originPrice_firm_halfyear =
                        i.originPrice;
                      this.goodsType[4] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                    case "年卡":
                      this.infoList[1].price_firm_year = i.price;
                      this.infoList[1].originPrice_firm_year = i.originPrice;
                      this.goodsType[5] = {
                        type: i.type,
                        typeId: i.id,
                        name: i.name,
                        id: i.id,
                      };
                      break;
                  }
                });
                break;
            }
          });
        });
      }
    },
    show_login() {
      // console.log(1);
      this.Login = true;
    },
    loginCh(val) {
      this.Login = val;
    },

    getconsult() {
      this.showLayer = true;
      this.consult = true;
      // console.log("获取报价");
    },

    formatCardNumber(e) {
      // 获取input的dom对象
      const input = e.target;
      // 获取当前光标的位置
      const cursorIndex = input.selectionStart;
      // 字符串中光标之前空格的个数
      const lineNumOfCursorLeft = (
        e.target.value.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      // 去掉所有空格的字符串
      const noLine = e.target.value.replace(/\s/g, "");
      // 去除格式不对的字符并重新插入空格的字符串
      const newCardNum = noLine
        .replace(/^[A-Za-z+\d]&/g, "")
        .replace(/([A-Za-z+\d]{4})/g, "$1 ")
        .replace(/\s$/, "");
      // 改后字符串中原光标之前空格的个数
      const newLineNumOfCursorLeft = (
        newCardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      // 光标在改后字符串中应在的位置
      const newCursorIndex =
        cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft;
      // 赋新值，nextTick保证空格不能手动输入或删除，只能按照规则自动填入
      this.$nextTick(() => {
        this.code_key = newCardNum;
        // 修正光标位置，nextTick保证在渲染新值后定位光标
        this.$nextTick(() => {
          // selectionStart、selectionEnd分别代表选择一段文本时的开头和结尾位置
          input.selectionStart = newCursorIndex;
          input.selectionEnd = newCursorIndex;
        });
      });
    },

    //激活码兑换
    codeBtn() {
      let newCode = this.code_key.replace(/\s*/g, "");
      if (newCode.length == 16) {
        // alert("激活码通过");
        if (this.code_ven) {
          // alert("验证码通过");

          // console.log(newCode);
          // console.log(this.code_ven);

          let data = {
            activationCode: newCode,
            code: this.code_ven,
            event: this.key_res_info.event,
            phone: this.userInfo.phone,
            tokenId: this.key_res_info.tokenId,
          };
          // console.log(data);

          VerifyActivationCode(data).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.$message({
                message: "激活成功！",
                type: "success",
              });
              this.code_ven = "";
              this.code_key = "";

              GetUserInfoById().then((res) => {
                if (res.code == 200) {
                  this.$store.commit("userInfo", { ...res.result });
                }
              });
            } else if (res.code == 500) {
              this.$message({
                message: res.message,
                type: "warning",
              });
            } else if (res.code == 503) {
              this.$message({
                message: res.message,
                type: "warning",
              });
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            message: "请输入验证码",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "请输入正确激活码",
          type: "warning",
        });
      }
      // if (this.code_ven) {
      //   this.$message({
      //     message: "恭喜你，这是一条成功消息",
      //     type: "success",
      //   });
      //   this.code_ven = "";
      // } else {
      //   this.$message.error("错了哦，这是一条错误消息");
      // }
    },

    lookMem() {
      // console.log("查看会员特权");
    },
    call() {
      // console.log("联系客服");
    },

    async getmember() {
      //
      // console.log(this.goodsType);
      // console.log(this.goodsInfo);
      // this.closeShow = false;
      //窗口计时
      this.date_minutes = "0" + parseInt(this.date / (1000 * 60));
      this.date_seconds = "00";
      this.date = 5 * 1000 * 60 - 1000;
      this.timer = setInterval(() => {
        let date_minutes = parseInt(
          (this.date % (1000 * 60 * 60)) / (1000 * 60)
        );
        let date_seconds = (this.date % (1000 * 60)) / 1000;
        if (date_minutes == 0) {
          this.date_minutes = "00";
        } else {
          this.date_minutes = "0" + date_minutes;
        }
        if (date_seconds < 10) {
          this.date_seconds = "0" + date_seconds;
        } else {
          this.date_seconds = date_seconds;
        }
        if (this.date == -1000) {
          this.consult = false;
          this.member = false;
          this.showLayer = false;
          clearInterval(this.timer);
        }
        this.date -= 1000;
      }, 1000);
      this.showLayer = true;
      this.member = true;

      //判断支付等级
      let outTradeNo = {};
      // let loading = null;
      this.msg = {
        QRCode: document.getElementById("QRCode"),
      };
      switch (this.grade) {
        //个人版支付界面
        case 1:
          this.outTradeNo = {
            day: moment().format("YYYYMMDDhhmmsssss") + "1",
            month: moment().format("YYYYMMDDhhmmsssss") + "2",
            season: moment().format("YYYYMMDDhhmmsssss") + "3",
            year: moment().format("YYYYMMDDhhmmsssss") + "4",
          };
          outTradeNo = this.outTradeNo;

          this.orderInfo = {
            day: {
              outTradeNo: outTradeNo.day,
              subject: "个人版(日卡)",
              totalAmount: this.infoList[0].price_person_day,
              commodityId: this.goodsType[0].typeId,
            },
            month: {
              outTradeNo: outTradeNo.month,
              subject: "个人版(月卡)",
              totalAmount: this.infoList[0].price_person_month,
              commodityId: this.goodsType[1].typeId,
            },
            season: {
              outTradeNo: outTradeNo.season,
              subject: "个人版(季卡)",
              totalAmount: this.infoList[0].price_person_season,
              commodityId: this.goodsType[2].typeId,
            },
            year: {
              outTradeNo: outTradeNo.year,
              subject: "个人版(年卡)",
              totalAmount: this.infoList[0].price_person_year,
              commodityId: this.goodsType[4].typeId,
            },
          };
          break;
        //初创团队版支付界面
        case 5:
          // console.log("团队版");
          this.outTradeNo = {
            halfyear: moment().format("YYYYMMDDhhmmsssss") + "5",
            year: moment().format("YYYYMMDDhhmmsssss") + "6",
          };
          this.orderInfo = {
            halfyear: {
              outTradeNo: this.outTradeNo.halfyear,
              subject: "初创团队版(半年卡)",
              totalAmount: this.infoList[1].price_firm_halfyear,
              commodityId: this.goodsType[4].typeId,
            },
            year: {
              outTradeNo: this.outTradeNo.year,
              subject: "初创团队版(年卡)",
              totalAmount: this.infoList[1].price_firm_year,
              commodityId: this.goodsType[5].typeId,
            },
          };

          break;
      }
    },
    selMem(id) {
      switch (id) {
        case 1:
          this.grade = 1;
          break;
        case 2:
          this.grade = 2;
          break;
        case 3:
          this.grade = 3;
          break;
        case 4:
          this.grade = 4;
          break;
        case 5:
          this.grade = 5;
          break;
        case 6:
          this.grade = 6;
          break;
      }
    },

    close() {
      //关闭遮罩层  关闭交易 (扫码但是未付款的交易)
      // console.log("关闭");
      clearInterval(this.timer_pay);
      clearInterval(this.timer);
      this.date = 5 * 1000 * 60;
      this.consult = false;
      this.member = false;
      this.member_preson = false;
      this.member_firm = false;
      this.showLayer = false;
      this.outTradeNo = {};
      // console.log(this.jsonArr);

      this.jsonArr = {
        person: {
          day: "",
          month: "",
          season: "",
          year: "",
        },
        firm: {
          halfyear: "",
          year: "",
        },
      };
      // let canvas = document.getElementById("QRCode");
      // let ctx = canvas.getContext("2d");
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.grade = 0;
      // this.$router.go(0); // return this.userInfo.id;
    },

    async pay(info) {
      // console.log(info);
      await TradePagePay({ ...info })
        .then((res) => {
          // console.log(res);
          if (res.code == 200) {
            let routerData = this.$router.resolve({
              path: "/apply",
              query: { htmls: res.result.url },
            });
            this.htmls = res.result.url;
            window.open(routerData.href, "_blank");
          }
        })
        .then(() => {
          this.close();
        });
    },

    GotoPay() {
      switch (this.grade) {
        case 1:
          // console.log(this.orderInfo.day);
          this.pay(this.orderInfo.day);
          break;
        case 2:
          // console.log(this.orderInfo.month);
          this.pay(this.orderInfo.month);
          break;
        case 3:
          // console.log(this.orderInfo.season);
          this.pay(this.orderInfo.season);
          break;
        case 4:
          // console.log(this.orderInfo.year);
          this.pay(this.orderInfo.year);
          break;
        case 5:
          // console.log(this.orderInfo.halfyear);
          this.pay(this.orderInfo.halfyear);
          break;
        case 6:
          // console.log(this.orderInfo.year);
          this.pay(this.orderInfo.year);
          break;
      }
    },

    //兑换码验证码
    getCodekey() {
      this.key_codeShow = true;
      this.key_timer = setInterval(() => {
        this.key_time--;
        if (this.key_time < 1) {
          clearInterval(this.key_timer);
          this.key_codeText = "重新发送";
          this.key_time = 60;
          this.key_codeShow = false;
        }
      }, 1000);
      let keyCode = {
        phone: this.userInfo.phone,
        event: "keyCode",
      };

      GetCode(keyCode).then((res) => {
        // console.log(res);
        this.$message({
          message: "验证码已发送，请注意查收!",
          type: "success",
          offset: 100,
        });
        if (res.code == 200) {
          this.key_res_info.tokenId = res.tokenId;
          this.key_res_info.event = keyCode.event;
          // console.log(this.key_res_info);
        } else {
          this.$message({
            message: res.message,
            type: "warning",
            offset: 100,
          });
        }
      });
      // console.log(keyCode);
    },
  },
  created() {
    this.$store.state.infoList.map((item) => {
      if (item.unread) {
        this.num++;
      }
    });
    this.userInfo = this.$store.state.userinfo.userInfo;
    // console.log(this.userInfo);
    for (let i in this.userInfo.phone) {
      if (i >= 3 && i <= 6) {
        this.userInfo_phone += "*";
      } else {
        this.userInfo_phone += this.userInfo.phone[i];
      }
    }
    /**
     * TODO:1.查找全局是否存在会员卡信息
     * TODO:2.查到 => get  未查到 => req
     */
    this.getPrice();
    //拿到做数据整合处理
  },
  watch: {
    grade() {
      // console.log(this.outTradeNo);
      switch (this.grade) {
        case 1:
          this.should_pay = this.infoList[0].price_person_day;
          // if (this.jsonArr.person.day) {
          //   this.getQRCode(this.jsonArr.person.day, this.msg.QRCode);
          // }
          // this.poll(this.outTradeNo.day);
          break;
        case 2:
          // this.getQRCode(this.jsonArr.person.month, this.msg.QRCode);
          this.should_pay = this.infoList[0].price_person_month;
          // this.poll(this.outTradeNo.month);
          break;
        case 3:
          // this.getQRCode(this.jsonArr.person.season, this.msg.QRCode);
          this.should_pay = this.infoList[0].price_person_season;
          // this.poll(this.outTradeNo.season);
          break;
        case 4:
          // this.getQRCode(this.jsonArr.person.year, this.msg.QRCode);
          this.should_pay = this.infoList[0].price_person_year;
          // this.poll(this.outTradeNo.year);
          break;
        case 5:
          // if (this.jsonArr.firm.halfyear) {
          //   this.getQRCode(this.jsonArr.firm.halfyear, this.msg.QRCode);
          // }
          // this.getQRCode(this.jsonArr.firm.halfyear, this.msg.QRCode);
          this.should_pay = this.infoList[1].price_firm_halfyear;
          // this.poll(this.outTradeNo.halfyear);
          break;
        case 6:
          // this.getQRCode(this.jsonArr.firm.year, this.msg.QRCode);
          this.should_pay = this.infoList[1].price_firm_year;
          // this.poll(this.outTradeNo.year);
          break;
      }
    },
    // code_key() {
    //   let newCode = this.code_key.replace(/\s*/g, "");
    //   // console.log(this.code_key.length);
    //   // console.log(newCode);
    //   this.code_key = newCode;
    // },
    $route: {
      handler: function (Val) {
        // console.log(Val);
        if (Val.name != "MemberCenter") {
          this.close();
        }
      },
      deep: true,
    },
    closeShow(val) {
      this.qrCodeShow = val;
    },
  },
};
</script>
