import axios from "./axios"; //引入axios
import router from "../router";
// 创建axios实例
const service = axios.create({
  headers: {
    //请求头
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
    post: {
      "Content-Type": "application/json",
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
  },
  settimeout: 50000, //超时时间
});
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么 验证token之类的
    if (localStorage.getItem("token")) {
      // let token = window.localStorage.getItem("token");
      // console.log(token);
      config.headers.token = window.localStorage.getItem("token");
      // localStorage.removeItem("userInfo");
      //   console.log(localStorage.getItem("token"));
      //   console.log("存入的token",token);
      //   console.log("config请求的数据:", config.headers.token);
    }
    return config; //记得一定要 返回config
  },
  (error) => {
    // 对请求错误做些什么
    console.log("error请求的数据:", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log("返回的数据", response);
    // 这里拦截401错误，并重新跳入登页重新获取token
    if (response.status && response.status === 200) {
      // 通讯成功
      if (response.data.code === 0) {
        return response.data;
      } else if (response.data.code == 401) {
        // 如果是token过期，跳转至登录
        // console.log("登录过期");
        // console.log("清除token");
        // localStorage.removeItem("token"); // 移除token，跳转至登录
        // localStorage.removeItem("userInfo");
        localStorage.clear();
        sessionStorage.clear();
        // this.$store.commit("userInfo", {});
        // this.$router.push("/");
        // this.$router.go(0);
        // alert("请重新登录!");
        // this.$router.go(0);
        router.push({
          path: "/",
        });
        router.go(0)
        // return alert("请重新登录!");
      }
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default service;
