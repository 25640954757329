<template>
  <div class="help_center">
    <!--  头部 -->
    <div class="nav_header">
      <router-link :to="'/'"><div class="left"></div></router-link>
      <div class="mid">
        <ul>
          <li>帮助中心</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="main_search">
        <div class="search">
          <!-- <div class="inp">
            <i class="el-icon-search"></i>
            <input type="text" placeholder="请输入您的问题" name="" id="" />
          </div> -->
          <!-- <div class="btn">搜索</div> -->
        </div>
      </div>
      <div class="main_problem">
        <div class="problem_list">
          <ul class="item" v-for="item in problemList" :key="item.value">
            <li>{{ item.title }}</li>
            <li
              v-for="i in item.children"
              :key="i.value"
              @click="getProblem(i)"
              :class="cur == i.value ? 'cur' : ''"
            >
              {{ i.title }}
            </li>
          </ul>
          <div class="contact">
            <p class="p_title">联系客服</p>
            <div class="btn">在线咨询</div>
            <p>电话</p>
            <p class="p_phone">0571-88055158</p>
            <p>关注公众号或添加企业微信</p>
            <div class="qrCode">
              <div class="qrCode_one code">
                <img src="../assets/image/QRcode/officialAccounts.jpg" alt="" />
              </div>
              <div class="qrCode_two code">
                <img src="../assets/image/QRcode/service.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="problem_content">
          <div class="content">
            <p class="p_content_title">{{ this.problemInfo.title }}</p>
            <ul>
              <router-link
                v-for="item in this.problemInfo.list"
                :key="item.id"
                target="_blank"
                :to="{
                  name: 'help_info',
                  query: { id: item.id },
                }"
              >
                <li>{{ item.title }}</li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetAllFAQCategory, GetFAQContentByTypePaging } from "../request/api";
export default {
  name: "App",
  data() {
    return {
      problemList: [],
      problemInfo: [],
      serveList: [],
      //
    };
  },

  async created() {
    this.problemList = this.$store.state.problemList;
    this.serveList = this.$store.state.serveList;
    this.guidance = this.$store.state.guidance;
    if (!this.problemList.length) {
      // console.log('重新获取');
      this.problemList = await GetAllFAQCategory().then((res) => {
        if (res.code == 200) {
          this.$store.commit("problemList", [...res.result]);
          return res.result;
        }
      });
    }

    console.log(this.problemList);
    this.cur = this.problemList[0].children[0].value;

    let id = {
      categoryId: this.problemList[0].children[0].value,
    };

    console.log(id);

    this.problemInfo = await GetFAQContentByTypePaging(id).then((res) => {
      console.log(res); 
      if (res.code == 200) {
        let info = {
          title: this.problemList[0].children[0].title,
          list: res.result,
        };
        return info;
      }
    });

    // console.log(this.problemInfo);
  },
  methods: {
    async getProblem(id) {
      console.log(id);
      this.cur = id.value;
      let info = {
        categoryId: id.value,
      };
      this.getgetProblemInfo(info, id.title);
    },
    async getgetProblemInfo(id, title) {
      this.problemInfo = await GetFAQContentByTypePaging(id).then((res) => {
        if (res.code == 200) {
          let info = {
            title: title,
            list: res.result,
          };
          return info;
        }
      });
    },
  },
  mounted() {},
};
</script>
