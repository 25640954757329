<template>
  <div class="helpInfo">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.helpInfo {
  // height: 100%;
  background-color: #f4f4f4;
  width: 60%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
