var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"help_center"},[_c('div',{staticClass:"nav_header"},[_c('router-link',{attrs:{"to":'/'}},[_c('div',{staticClass:"left"})]),_vm._m(0)],1),_c('div',{staticClass:"main"},[_vm._m(1),_c('div',{staticClass:"main_problem"},[_c('div',{staticClass:"problem_list"},[_vm._l((_vm.problemList),function(item){return _c('ul',{key:item.value,staticClass:"item"},[_c('li',[_vm._v(_vm._s(item.title))]),_vm._l((item.children),function(i){return _c('li',{key:i.value,class:_vm.cur == i.value ? 'cur' : '',on:{"click":function($event){return _vm.getProblem(i)}}},[_vm._v(" "+_vm._s(i.title)+" ")])})],2)}),_vm._m(2)],2),_c('div',{staticClass:"problem_content"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"p_content_title"},[_vm._v(_vm._s(this.problemInfo.title))]),_c('ul',_vm._l((this.problemInfo.list),function(item){return _c('router-link',{key:item.id,attrs:{"target":"_blank","to":{
                name: 'help_info',
                query: { id: item.id },
              }}},[_c('li',[_vm._v(_vm._s(item.title))])])}),1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mid"},[_c('ul',[_c('li',[_vm._v("帮助中心")]),_c('li'),_c('li'),_c('li')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_search"},[_c('div',{staticClass:"search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('p',{staticClass:"p_title"},[_vm._v("联系客服")]),_c('div',{staticClass:"btn"},[_vm._v("在线咨询")]),_c('p',[_vm._v("电话")]),_c('p',{staticClass:"p_phone"},[_vm._v("0571-88055158")]),_c('p',[_vm._v("关注公众号或添加企业微信")]),_c('div',{staticClass:"qrCode"},[_c('div',{staticClass:"qrCode_one code"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/officialAccounts.jpg"),"alt":""}})]),_c('div',{staticClass:"qrCode_two code"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/service.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }