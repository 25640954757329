<template>
  <div class="nav" id="nav">
    <div class="right">
      <!-- <div class="vip_sp" v-if="this.userInfo.membership">
        您的VIP会员即将到期，快来续费吧！
      </div> -->
      <div class="vip_cen">
        <router-link to="/membercenter">
          <img
            class="icon"
            src="../assets/image/live_memberCenter.png"
            alt=""
          />
          <span v-show="!Index_page" style="color: aliceblue">会员中心</span>
          <span v-show="Index_page" style="color: #000">会员中心</span>
        </router-link>
        <router-link to="/livedemo/messagecenter">
          <el-badge
            :value="this.num"
            class="item"
            style="cursor: pointer"
            :hidden="this.num ? false : true"
          >
            <img
              src="../assets/image/live_info.png"
              style="cursor: pointer"
              alt=""
              v-show="Index_page"
            />
            <img
              src="../assets/image/live_info_2.png"
              style="cursor: pointer"
              alt=""
              v-show="!Index_page"
            />
          </el-badge>
        </router-link>
      </div>
      <div class="user_info" id="user_info">
        <div class="userhead">
          <img :src="this.userInfo ? this.userInfo.avatarUrl : ''" alt="" />
        </div>
        <div class="userinfo">
          <span v-show="!Index_page" style="color: aliceblue">{{
            this.userInfo ? this.userInfo.nickName : ""
          }}</span>
          <span v-show="Index_page" style="color: #000">{{
            this.userInfo ? this.userInfo.nickName : ""
          }}</span>
          <img
            src="../assets/image/vip/VIP_person.png"
            alt=""
            v-if="this.userInfo.vipStatus && this.userInfo.vipLevel == 1"
          />
          <img
            src="../assets/image/vip/VIP_team.png"
            alt=""
            v-if="this.userInfo.vipStatus && this.userInfo.vipLevel == 2"
          />
          <img
            src="../assets/image/vip/VIP_firm.png"
            alt=""
            v-if="this.userInfo.vipStatus && this.userInfo.vipLevel == 3"
          />
        </div>
      </div>
      <div class="nav_fun">
        <div class="fun_main">
          <div class="fun_header">
            Hi,{{ this.userInfo ? this.userInfo.nickName : "" }}
          </div>
          <router-link :to="'/membercenter'" v-if="!userInfo.vipStatus">
            <div class="fun_memcenter"></div
          ></router-link>

          <router-link :to="'/membercenter'" v-if="userInfo.vipStatus">
            <div class="fun_memcenter_vip">
              <p>
                <span>
                  {{
                    this.userInfo.vipStatus
                      ? this.userInfo.vipOverTime.slice(0, 10)
                      : ""
                  }}
                  到期</span
                >
                <span>
                  <i class="el-icon-arrow-right"></i>
                </span>
              </p></div
          ></router-link>

          <router-link to="/livedemo/userInfo"
            ><div class="fun_info fun_list" @click="goUserInfo">
              <span>
                <img src="../assets/image/icon/icon_04.png" alt="" />
                个人信息
              </span>
              <img
                style="width: 0.875rem; height: 0.875rem"
                src="../assets/image/icon/icon_02.png"
                alt=""
              /></div
          ></router-link>
          <div class="fun_share fun_list" @click="share">
            <span>
              <img src="../assets/image/icon/icon_06.png" alt="" />
              分享好友
            </span>
            <img
              style="width: 0.875rem; height: 0.875rem"
              src="../assets/image/icon/icon_02.png"
              alt=""
            />
          </div>
          <div class="fun_switchAccount fun_list" @click="switchAccount">
            <span>
              <img src="../assets/image/icon/icon_01.png" alt="" />
              切换账号
            </span>
            <img
              style="width: 0.875rem; height: 0.875rem"
              src="../assets/image/icon/icon_02.png"
              alt=""
            />
          </div>
          <div class="fun_logoff fun_list" @click="logout">
            <span>
              <img src="../assets/image/icon/icon_05.png" alt="" />
              退出登录
            </span>
            <img
              style="width: 0.875rem; height: 0.875rem"
              src="../assets/image/icon/icon_02.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="nav_fun">
      <div class="fun_main">
        <div class="fun_header">
          Hi,{{ this.userInfo ? this.userInfo.nickName : "" }}
        </div>
        <router-link :to="'/membercenter'" v-if="!userInfo.vipStatus">
          <div class="fun_memcenter"></div
        ></router-link>

        <router-link :to="'/membercenter'" v-if="userInfo.vipStatus">
          <div class="fun_memcenter_vip">
            <p>
              {{
                this.userInfo.vipStatus
                  ? this.userInfo.vipOverTime.slice(0, 10)
                  : ""
              }}&emsp;
              <span>续费</span>
            </p>
          </div></router-link
        >

        <router-link to="/livedemo/userInfo"
          ><div class="fun_info fun_list" @click="goUserInfo">
            <span>
              <img src="../assets/image/icon/icon_04.png" alt="" />
              个人信息
            </span>
            <img
              style="width: 0.875rem; height: 0.875rem"
              src="../assets/image/icon/icon_02.png"
              alt=""
            /></div
        ></router-link>
        <div class="fun_share fun_list" @click="share">
          <span>
            <img src="../assets/image/icon/icon_06.png" alt="" />
            分享好友
          </span>
          <img
            style="width: 0.875rem; height: 0.875rem"
            src="../assets/image/icon/icon_02.png"
            alt=""
          />
        </div>
        <div class="fun_switchAccount fun_list" @click="switchAccount">
          <span>
            <img src="../assets/image/icon/icon_01.png" alt="" />
            切换账号
          </span>
          <img
            style="width: 0.875rem; height: 0.875rem"
            src="../assets/image/icon/icon_02.png"
            alt=""
          />
        </div>
        <div class="fun_logoff fun_list" @click="logout">
          <span>
            <img src="../assets/image/icon/icon_05.png" alt="" />
            退出登录
          </span>
          <img
            style="width: 0.875rem; height: 0.875rem"
            src="../assets/image/icon/icon_02.png"
            alt=""
          />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { GetUserInfoById } from "../request/api";
// import { mapState } from "vuex";
export default {
  name: "App",
  props: {
    isFixed: Boolean,
  },
  data() {
    return {
      userInfo: {},
      //
      num: 0,
      UserInfoShow: true,
      MessageShow: true,
      FunShow: false,
      Index_page: false,
    };
  },
  computed: {
    // ...mapState(["userInfo"]),
  },
  methods: {
    showFun() {
      this.FunShow = true;
    },
    closeFun() {
      this.FunShow = false;
    },

    goUserInfo() {
      this.$emit("getShow", this.UserInfoShow);
    },
    share() {
      this.$message({
        message: "此功能未开通",
      });
    },
    switchAccount() {
      this.$message({
        message: "此功能未开通",
      });
    },
    //退出账号
    logout() {
      this.$confirm("此操作将退出当前账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //退出账号

        localStorage.clear();
        sessionStorage.clear();
        this.$store.commit("userInfo", {});
        this.$router.push("/");
        this.$router.go(0);
        this.$message({
          type: "success",
          message: "退出成功!",
        });
      });
      // .catch(() => {
      //   this.$message({
      //     type: "info",
      //     message: "已取消",
      //   });
      // });
    },

    goMessageCenter() {
      this.$emit("getMessageShow", this.MessageShow);
    },
    QueryPayInfo() {
      GetUserInfoById().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          let searchUserInfo = res.result.userInfo;
          let flag = false;
          for (let i in searchUserInfo) {
            for (let j in this.userInfo) {
              if (i == j) {
                if (searchUserInfo[i] != this.userInfo[j]) {
                  this.userInfo[j] = searchUserInfo[i];
                  // console.log(searchUserInfo[i]);
                  flag = true;
                }
              }
            }
          }
          if (flag) {
            // console.log("更新");
            this.$store.commit("userInfo", { ...res.result });
            // console.log(this.userInfo);
          }
        }
      });
    },
  },
  created() {
    this.$store.state.infoList.map((item) => {
      if (item.unread) {
        this.num++;
      }
    });
    // this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    this.userInfo = this.$store.state.userinfo.userInfo;
    // this.userInfo.vipStatus = false;
    // console.log(this.userInfo.vipStatus);
    // console.log(this.isFixed);
    // if (this.$route.name == "Index") {
    //   this.Index_page = false;
    // } else {
    //   this.Index_page = true;
    // }

    if (this.$route.name == "Index") {
      if (this.isFixed) {
        this.Index_page = true;
      } else {
        this.Index_page = false;
      }
    } else {
      this.Index_page = true;
    }
    //进页面查询信息比对
    this.QueryPayInfo();
  },
  mounted() {},
  watch: {
    "$store.state.infoList": {
      deep: true, //深度监听
      handler(newValue) {
        // console.log(newValue);
        this.num = 0;
        for (let i in newValue) {
          if (newValue[i].unread) {
            this.num++;
          }
        }
      },
    },

    "$store.state.userinfo": {
      deep: true, //深度监听
      handler(newValue) {
        // console.log(newValue);
        this.userInfo = { ...newValue.userInfo };
      },
    },

    $route: {
      handler(val) {
        // console.log(val);
        // console.log(this.Index_page);
        if (val.name == "Index") {
          this.Index_page = false;
        } else {
          this.closeFun();
          this.Index_page = true;
        }
      },
      deep: true,
    },
    isFixed(val) {
      if (this.$route.name == "Index") {
        if (val) {
          this.Index_page = true;
        } else {
          this.Index_page = false;
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-badge__content {
  height: 1.125rem;
  border-radius: 0.625rem;
  line-height: 1.125rem;
  font-size: 0.75rem;
  border: 0.0625rem solid #fff;
  padding: 0 .375rem;
}
.nav {
  height: 3.75rem;
  // box-shadow: 0 0.25rem 0.625rem 0 #f1f3f9;
  display: flex;
  justify-content: space-between;
  position: relative;

  .left {
    width: 5.625rem;
    height: 100%;
    padding: 0.875rem 0 0.875rem 0;
    margin-left: 5.625rem;
    box-sizing: border-box;

    img {
      width: 5.5rem;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;

    .vip_sp {
      font-size: 0.875rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a4abc0;
      line-height: 1.25rem;
      box-sizing: border-box;
      padding: 1.1875rem 0 0 0;
    }

    .vip_cen {
      // height: 1.25rem;
      // margin-top: 1.125rem;
      padding-right: 1.125rem;
      border-right: 0.0625rem solid #ebedf2;
      display: flex;
      align-items: center;
      .icon {
        width: 1.1rem;
        height: 1.1rem;
      }

      // background-color: #000;
      .item {
        // height: 1.25rem;
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      span {
        font-size: 0.875rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191f33;
        line-height: 1.25rem;
        margin: 0 1.375rem 0 0.3125rem;
      }
    }

    .user_info {
      margin-top: 0.75rem;
      margin-right: 1.375rem;
      height: 100%;
      padding: 0 0 0 1rem;
      display: flex;
      justify-content: space-between;

      .userhead {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        // background-color: #f0f;
        margin-right: 0.4375rem;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .userinfo {
        padding: 0.375rem 0 0 0;

        span {
          height: 1.25rem;
          font-size: 0.875rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #191f33;
          line-height: 1.25rem;
          margin-right: 0.5rem;
        }

        img {
          width: 2rem;
          height: 0.625rem;
        }
      }
    }
  }

  .nav_fun {
    transition: 0.3s ease;
    // width: 13.125rem;
    width: 0;
    // height: 16.25rem;
    height: 0;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    left: 8rem;
    top: 3.5rem;
    border-radius: 0.5rem;
    z-index: 999;
    box-shadow: 0.3125rem 0.1875rem 0.625rem 0 #909096;
    // padding: 0.9375rem;
    box-sizing: border-box;
    .fun_main {
      padding: 0.9375rem;
    }
    div {
      width: 11.25rem;
      height: 2.5rem;
      border-radius: 0.5rem;
    }
    .fun_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0.5625rem;
      line-height: 2.5rem;
      span {
        font-size: 0.75rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 0.9375rem;
        img {
          font-size: 0;
          margin-right: 0.25rem;
        }
      }
    }
    .fun_list:hover {
      background-color: #f5f6fc;
    }
    .fun_header {
      height: 1.375rem;
      text-align: center;
      font-size: 1rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #191f33;
      line-height: 1.375rem;
    }
    .fun_memcenter {
      margin: 0.875rem 0 0.25rem 0;
      width: 11.25rem;
      height: 2.5rem;
      background-image: url("../assets/image/vip/VIP_back_02.png");
      background-size: 11.25rem 2.5rem;
    }
    .fun_memcenter_vip {
      margin: 0.875rem 0 0.25rem 0;
      width: 11.25rem;
      // height: 2.5rem;
      background-image: url("../assets/image/vip/VIP_back.png");
      background-size: 11.25rem 2.5rem;
      box-sizing: border-box;
      p {
        line-height: 2.5rem;
        text-align: left;
        padding-left: 1.25rem;
        color: #644817;
        font-size: 0.75rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding: 0;
        padding: 0 0.75rem;
        span {
          padding-right: 0.125rem;
          i {
            font-weight: 900;
          }
        }
      }
    }
  }
}
// #nav:hover .nav_fun {
//   height: 16.25rem;
//   transition: 0.3s ease;
// }
.nav_fun:hover {
  height: 16.25rem;
  width: 13.125rem;
}

#user_info:hover ~ .nav_fun {
  height: 16.25rem;
  width: 13.125rem;
  transition: 0.3s ease;
  // background-color: red;
}

.index_page {
  color: aliceblue !important;
}
</style>
