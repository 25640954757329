<template>
  <div class="agrement">
    <div class="header"></div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.agrement {
  min-width: 1200px;
  background-color: #fff;
  .header{
    height: 2.8125rem;
    background-color: #F5F6FB;
  }
}
</style>
