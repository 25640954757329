import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //用户信息
    userinfo: {},
    //用户保存文案
    writeList: [],
    //用户文案总数
    writeTotal: 0,
    //
    infoList: [
      {
        id: 1,
        rank: "",
        unread: true,
        title: "欢迎来到书营AI写作星球～",
        main: "在书营AI你可以体验到更高效、准确、优质的写作服务，帮你省去繁琐的写作流程，更专注于内容创作。如果有什么问题，可以即刻与我们联系，我们将竭尽全力为您解决！如果我们有什么地方做的不足，也欢迎您指正，我们将虚心接受，期待为您提供最好的写作体验！好了，让我们一起释放创造力，创作出独一无二的文本吧！",
        time:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate() +
          " ",
        // +
        // new Date().getHours() +
        // ":" +
        // new Date().getMinutes() +
        // ":" +
        // new Date().getSeconds(),
      },
    ],
    problemList: [],
    serveList: [
      {
        serveId: 1,
        serveTitle: "忘记登录密码，如何找回？",
      },
      {
        serveId: 2,
        serveTitle: "忘记登录密码，如何找回？",
      },
      {
        serveId: 3,
        serveTitle: "忘记登录密码，如何找回？",
      },
    ],
    guidance: [
      {
        guidanceId: 1,
        guidanceTitle: "忘记登录密码，如何找回？",
      },
      {
        guidanceId: 2,
        guidanceTitle: "忘记登录密码，如何找回？",
      },
      {
        guidanceId: 3,
        guidanceTitle: "忘记登录密码，如何找回？",
      },
    ],
  },
  mutations: {
    userInfo(state, newVal) {
      state.userinfo = newVal;
    },
    infoList(state, newVal) {
      state.infoList = newVal;
    },
    writeList(state, newVal) {
      state.writeList = newVal;
    },
    writeTotal(state, newValue) {
      state.writeTotal = newValue;
    },
    problemList(state, newValue) {
      state.problemList = newValue;
    },
  },
  actions: {},
  modules: {},
});
