import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import LiveDemo from "../views/LiveDemo.vue";
import WorkBench from "../views/Workbench.vue";
import MemberCenter from "../views/MemberCenter.vue";
import ProPre from "../views/ProductPresentation.vue";
import HelpCenter from "../views/HelpCenter.vue";
import Test from "../views/test.vue";
import NotFind from "../views/NotFind.vue";
import Apply from "../views/Apply.vue";
//
import HelpInfo from "../views/HelpInfo.vue";
import Agrement from "../views/Agrement.vue";
Vue.use(VueRouter);

const routes = [
  //主页
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      isLogin: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: NotFind,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      isLogin: true,
    },
  },
  {
    path: "/apply",
    name: "Apply",
    component: Apply,
    meta: {
      keepAlive: false, //此组件不需要被缓存
      isLogin: true,
    },
  },
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      keepAlive: false, //此组件不需要被缓存
    },
  },
  //工作台
  {
    path: "/livedemo",
    name: "LiveDemo",
    component: LiveDemo,
    meta: { isLogin: true, keepAlive: false },
    children: [
      { path: "/", redirect: "/livedemo/livefunlist" },
      {
        path: "livefunlist",
        name: "livefunlist",
        component: () => import("../components/liveFunList.vue"),
        meta: { isLogin: true },
      },
      {
        path: "livemywoke",
        name: "livemywoke",
        component: () => import("../components/liveMyWork.vue"),
        meta: { isLogin: true, keepAlive: true },
      },
      {
        path: "userInfo",
        name: "userInfo",
        component: () => import("../components/userInfo.vue"),
        meta: { isLogin: true },
      },
      {
        path: "messagecenter",
        name: "messagecenter",
        component: () => import("../components/messageCenter.vue"),
        meta: { isLogin: true },
      },
    ],
  },
  {
    path: "/workbench",
    name: "WorkBench",
    component: WorkBench,
    meta: {
      keepAlive: false,
    },
    children: [
      { path: "/", redirect: "/workbench/workwrite" },
      {
        path: "workwrite",
        name: "workwrite",
        component: () => import("../components/workWrite.vue"),
        meta: { isLogin: true, keepAlive: false },
      },
      {
        path: "workamend",
        name: "workamend",
        component: () => import("../components/workAmend.vue"),
        meta: { isLogin: true, keepAlive: false },
      },
    ],
  },
  {
    path: "/membercenter",
    name: "MemberCenter",
    component: MemberCenter,
    meta: { isLogin: true },
  },
  {
    path: "/productpresentation",
    name: "ProPre",
    component: ProPre,
    meta: {
      keepAlive: false, //此组件不需要被缓存
    },
  },
  {
    path: "/helpcenter",
    name: "HelpCenter",
    component: HelpCenter,
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/helpinfo",
    name: "HelpInfo",
    component: HelpInfo,
    meta: {
      keepAlive: false, //此组件不需要被缓存
    },
    children: [
      { path: "/", redirect: "/helpinfo/help_info" },
      {
        path: "help_info",
        name: "help_info",
        component: () => import("../components/helpInfo/help_info"),
      },
    ],
  },

  {
    path: "/showwin_agrement",
    name: "showwin_agrement",
    component: Agrement,
    meta: {
      keepAlive: false, //此组件不需要被缓存
    },
    children: [
      { path: "/", redirect: "/showwin_agrement/showwin_agrement_privacy" },
      {
        path: "showwin_agrement_privacy",
        name: "showwin_agrement_privacy",
        component: () => import("../components/showwin_agrement/privacy"),
      },
      {
        path: "showwin_agrement_user",
        name: "showwin_agrement_user",
        component: () => import("../components/showwin_agrement/user"),
      },
      {
        path: "showwin_agrement_service",
        name: "showwin_agrement_service",
        component: () => import("../components/showwin_agrement/service"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

//路由守卫
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

// 配置路由权限
router.beforeEach((to, from, next) => {
  //滚动条回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // console.log(from);
  if (to.meta.isLogin) {
    let LoginName = window.localStorage.getItem("token");
    if (!LoginName) {
      alert("请先登录!");
      localStorage.clear();
      sessionStorage.clear();
      // this.$store.commit("userInfo", {});
      next({
				path: '/'
			})
      window.location.reload() 
      // window.location = to.path;
    }
  }
  next();
});

export default router;
