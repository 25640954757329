<template>
  <div class="side" :style="{ left: this.width - 140 + 'px' }">
    <div class="main">
      <div class="side-header">
        <div class="service">
          <img src="../assets/image/AI (1).png" alt="" />
        </div>
        <img
          style="
            width: 7.5rem;
            height: 3.625rem;
            position: absolute;
            top: 0;
            left: 0.625rem;
            z-index: 99999;
          "
          src="../assets/image/side_consult.png"
          alt=""
        />
      </div>
      <div class="side-footer">
        <div
          class="mid-one"
          v-on:mouseleave="mid_one_leave"
          v-on:mouseenter="mid_one_enter"
        >
          <img src="../assets/image/side_telConsult.png" alt="" />
          <p>电话咨询</p>
        </div>
        <div
          class="mid-two"
          v-on:mouseleave="mid_two_leave"
          v-on:mouseenter="mid_two_enter"
        >
          <img src="../assets/image/officialAccounts.png" alt="" />
          <p>关注公众号</p>
        </div>
        <div @click="backTop" class="backHeader"></div>
        <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap">
          <div class="backHeader"></div>
        </el-backtop> -->
      </div>
      <div class="tel" v-show="this.telShow">
        <p>客户热线:</p>
        <p>0571-88055158</p>
      </div>
      <div class="weixin" v-show="this.weixinShow">
        <div class="img">
          <img src="../assets/image/QRcode/officialAccounts.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      telShow: false,
      weixinShow: false,
      width: document.body.clientWidth,
      timer: null,
    };
  },
  methods: {
    mid_one_leave: function () {
      this.telShow = false;
    },
    mid_one_enter: function () {
      console.log("进入");
      this.telShow = true;
    },
    mid_two_leave: function () {
      this.weixinShow = false;
    },
    mid_two_enter: function () {
      // console.log(e);
      // console.log("进入");
      this.weixinShow = true;
    },
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    handleResize() {
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          // location.reload();
          // this.$router.go(0);
          this.width = document.body.clientWidth;
        }, 500);
      });
      clearInterval(this.timer);
    },
  },
  created() {
    // console.log(this.width);
  },
  mounted() {
    // let width = document.body.clientWidth;
    // console.log(width);
    window.addEventListener("resize", this.handleResize);
  },
  // watch: {
  //   width(val) {
  //     console.log(val);
  //   },
  // },
};
</script>
<style lang='scss' scoped>
.side {
  position: fixed;
  // position: absolute;
  // left: 111rem;
  z-index: 99999;
}
.main {
  width: 8.75rem;
  height: 21.25rem;
  //   background-color: red;
  margin: 6.25rem auto;
  box-sizing: border-box;
  position: relative;
  .side-header {
    width: 7.5rem;
    height: 3.125rem;
    background-color: transparent;
    margin-bottom: 0.125rem;
    margin: 0 auto;
    .service {
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 50%;
      box-sizing: border-box;
      border: solid 0.125rem #d5dfff;
      position: absolute;
      top: -4.5625rem;
      left: 1.5625rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .side-footer {
    width: 6.25rem;
    height: 15rem;
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 0 auto;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    .mid-one {
      width: 100%;
      height: 6.25rem;
      box-sizing: border-box;
    }
    .mid-one:hover .tel {
      color: red;
      display: block;
    }
    img {
      width: 3.375rem;
      height: 3.375rem;
    }
    .backHeader {
      width: 1.25rem;
      height: 1.25rem;
      margin: 1.5rem auto;
      cursor: pointer;
      background-image: url("../assets/image/side_backTop_01.png");
      background-size: 1.25rem 1.25rem;
    }
    .backHeader:hover {
      background-image: url("../assets/image/side_backTop_02.png");
      background-size: 1.25rem 1.25rem;
    }
    p {
      font-size: 0.875rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191f33;
      line-height: 1.25rem;
    }
  }
  .tel {
    // display: none;
    width: 15.25rem;
    height: 6.25rem;
    // background-color: springgreen;
    position: absolute;
    top: 2.625rem;
    left: -15.625rem;
    background-image: url("../assets/image/side_telBack.png");
    background-size: 15.25rem 6.25rem;
    box-sizing: border-box;
    padding: 1.5625rem 0 0 1.6875rem;
    p {
      text-align: left;
      font-size: 0.875rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.25rem;
    }
    p:nth-child(2) {
      font-size: 1.5rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 2.0625rem;
    }
  }
  .weixin {
    width: 6.875rem;
    height: 8.125rem;
    background-image: url("../assets/image/side_qrcode.png");
    background-size: 6.875rem 8.125rem;
    position: absolute;
    top: 8.75rem;
    left: -7.5rem;
    border-radius: 0.5rem;
    .img {
      width: 5rem;
      height: 5rem;
      margin: 1rem auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
