import requests from "./request";

/**
 * TODO: 验证码接口
 */
//获取验证码
export const GetCode = (data) => {
  return requests({ url: "/sms/sendMessage", method: "post", data });
};

/**
 * TODO: 用户接口2
 */
//测试接口
export const Test = () => {
  return requests({ url: "/user/wx/getAllUser", method: "get" });
};
//密码登录
export const LoginByPwd = (data) => {
  return requests({ url: "/user/pc/loginByPassword", method: "post", data });
};
//验证码登录
export const LoginByCode = (data) => {
  return requests({ url: "/user/pc/loginByCode", method: "post", data });
};
//新用户注册
export const Registry = (data) => {
  return requests({ url: "/user/pc/registryByCode", method: "post", data });
};
//根据用户手机号查询用户信息
export const GetUserInfoByPhone = (phone) => {
  return requests({
    url: "/user/pc/getUserByPhone/" + phone,
    method: "get",
  });
};
//根据用户id号查询用户信息
export const GetUserInfoById = () => {
  return requests({
    url: "/user/pc/getUserById",
    method: "get",
  });
};
//修改用户信息updateUserById
export const UpdateUserById = (data) => {
  return requests({ url: "/user/pc/updateUserById", method: "post", data });
};
//修改用户密码 /pc/forgetPassword
export const UpdatePassword = (data) => {
  return requests({ url: "/user/pc/updatePassword", method: "post", data });
};
//忘记密码
export const ForgetPassword = (data) => {
  return requests({ url: "/user/pc/forgetPassword", method: "post", data });
};

//激活码兑换接口
export const VerifyActivationCode = (data) => {
  return requests({
    url: "/user/pc/verifyActivationCode",
    method: "post",
    data,
  });
};

/**
 * TODO: 用户作品接口
 */
//作品
//根据用户id查询用户作品
export const GetUserWorkByUserId = (data) => {
  return requests({
    url: "/copyWriting/getUserWorkByUserId",
    method: "post",
    data,
  });
};
export const GetUserWorkById = (id) => {
  return requests({ url: "/copyWriting/getUserWorkById/" + id, method: "get" });
};

//修改文案/updateUserWork
export const AddUserWork = (data) => {
  return requests({
    url: "/copyWriting/addUserWork",
    method: "post",
    data,
  });
};
//保存文案
export const UpdateUserWork = (data) => {
  return requests({
    url: "/copyWriting/updateUserWork",
    method: "post",
    data,
  });
};
//根据id删除文案
export const DeleteUserWorkById = (id) => {
  return requests({
    url: "/copyWriting/deleteUserWorkById/" + id,
    method: "delete",
  });
};

/**
 * TODO: 生成文案功能接口
 */
//文案生成接口
//小红书
export const GetText_rb = (data) => {
  return requests({
    url: "/copyWriting/pc/rb/getText",
    method: "post",
    data,
  });
};
//豆瓣
export const GetText_db = (data) => {
  return requests({
    url: "/copyWriting/pc/db/getText",
    method: "post",
    data,
  });
};
//微博
export const GetText_wb = (data) => {
  return requests({
    url: "/copyWriting/pc/wb/getText",
    method: "post",
    data,
  });
};
//百度广告
export const GetText_bd = (data) => {
  return requests({
    url: "/copyWriting/pc/bd/getAdvertisementFromBd",
    method: "post",
    data,
  });
};
//电商广告
export const GetText_ds = (data) => {
  return requests({
    url: "/copyWriting/pc/ds/getAdvertisementFromDs",
    method: "post",
    data,
  });
};
//视频脚本
export const GetText_jiaoben = (data) => {
  return requests({
    url: "/copyWriting/pc/zh/getZhWeb",
    method: "post",
    data,
  });
};
//知乎问答
export const GetText_wenda = (data) => {
  return requests({
    url: "/copyWriting/pc/zh/getZhResp",
    method: "post",
    data,
  });
};

//每日一笑
export const GetText_joke = () => {
  return requests({
    url: "/copyWriting/pc/joke/getJokeText",
    method: "get",
  });
};

//小学生日记
export const GetRjText = (data) => {
  return requests({
    url: "/copyWriting/pc/rj/getRjText",
    method: "post",
    data,
  });
};

//周公解梦
export const GetZGJM = (data) => {
  return requests({
    url: "/copyWriting/pc/getZGJM",
    method: "post",
    data,
  });
};

//书名
export const GetSM = (data) => {
  return requests({
    url: "/copyWriting/pc/getSM",
    method: "post",
    data,
  });
};

//对联
export const GetDL = (data) => {
  return requests({
    url: "/copyWriting/pc/getDL",
    method: "post",
    data,
  });
};

//诗词集
export const GetPoem = (data) => {
  return requests({
    url: "/copyWriting/pc/getPoem",
    method: "post",
    data,
  });
};

//菜谱
export const GetCaipu = (data) => {
  return requests({
    url: "/copyWriting/pc/getCaipu",
    method: "post",
    data,
  });
};

//水印视频生成下载 /downloadFile  /removeWaterMark
export const RemoveWaterMark = (text) => {
  return requests({
    url: "/copyWriting/pc/removeWaterMark?text=" + text,
    method: "get",
  });
};

export const downloadFile = (videoUrl) => {
  return requests({
    url: "/copyWriting/pc/downloadFile?videoUrl=" + videoUrl,
    method: "get",
    responseType: "blob",
  });
};

//名言警句   /pc/getWAJ
export const GetWAJ = (data) => {
  return requests({
    url: "/copyWriting/pc/getWAJ",
    method: "post",
    data,
  });
};

//对话
export const get_dialogZhenhuan = (data) => {
  return requests({
    url: "/copyWriting/pc/dialogZhenhuan",
    method: "post",
    data,
  });
};

//gpt
export const get_GPT = (data) => {
  return requests({
    url: "/copyWriting/pc/gpt/getText",
    method: "post",
    data,
  });
};

//图转文
export const get_TextFromImage = (data) => {
  return requests({
    url: "/copyWriting/pc/getTextFromImage",
    method: "post",
    filePath: data,
  });
};

/**
 * TODO: 会员信息接口
 */
//会员卡信息
//获取所以会员卡信息
export const GetAllCategory = () => {
  return requests({
    url: "/order/category/getAllCategory",
    method: "get",
  });
};
//根据type获取商品信息
export const QueryCommodityByType = (data) => {
  return requests({
    url: "/order/commodity/queryCommodityByType",
    method: "post",
    data,
  });
};

//付款二维码
export const GeneratePrePayLink = (data) => {
  return requests({
    url: "/alipay/generatePrePayLink",
    method: "post",
    data,
  });
};

//付款跳转页面
export const TradePagePay = (data) => {
  return requests({
    url: "/alipay/cwp/tradePagePay",
    method: "post",
    data,
  });
};
//轮询查看支付信息
export const TradeQuery = (data) => {
  return requests({
    url: "/alipay/tradeQuery",
    method: "post",
    data,
  });
};

//临时
//关闭交易通道
export const TradeCancel = (data) => {
  return requests({
    url: "/alipay/tradeCancel",
    method: "post",
    data,
  });
};

export const TradeClose = (data) => {
  return requests({
    url: "/alipay/tradeClose",
    method: "post",
    data,
  });
};

/**
 * TODO: 帮助问题接口
 */
//问题接口
//分页查询所有接口

export const GetFAQContentById = (id) => {
  return requests({
    method: "GET",
    url: "/faq/content/getFAQContentById/" + id,
  });
};

export const GetAllFAQCategory = () => {
  return requests({
    url: "/faq/category/getFAQCategoryTree",
    method: "get",
  });
};

//查询问题详细
export const GetFAQContentByTypePaging = (data) => {
  return requests({
    url: "/faq/content/getFAQContentByTypePaging",
    method: "post",
    data,
  });
};
