<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="this.$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive" />
  </div>
</template>


<script>
import { GetUserInfoById } from "./request/api";
export default {
  name: "App",
  data() {
    return {
      token: "",
    };
  },
  methods: {},
  async created() {
    window.addEventListener("beforeunload", () => {
      if (window.localStorage.getItem("token").length) {
        // console.log("刷新前触发");
        sessionStorage.setItem(
          "userinfo",
          JSON.stringify(this.$store.state.userinfo)
        );
        sessionStorage.setItem(
          "writeList",
          JSON.stringify(this.$store.state.writeList)
        );
        sessionStorage.setItem("writeTotal", this.$store.state.writeTotal);
        sessionStorage.setItem(
          "problemList",
          JSON.stringify(this.$store.state.problemList)
        );
      }
    });

    if (sessionStorage.getItem("writeList")) {
      if (Object.keys(sessionStorage.getItem("writeList")).length != 0) {
        let writeList = JSON.parse(sessionStorage.getItem("writeList"));
        this.$store.commit("writeList", [...writeList]);
      }
    }

    if (sessionStorage.getItem("writeTotal")) {
      let writeTotal = sessionStorage.getItem("writeTotal") * 1;
      this.$store.commit("writeTotal", writeTotal);
    }

    if (sessionStorage.getItem("userinfo")) {
      if (Object.keys(sessionStorage.getItem("userinfo")).length != 0) {
        let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
        this.$store.commit("userInfo", { ...userinfo });
      }
    }
    if (sessionStorage.getItem("problemList")) {
      if (Object.keys(sessionStorage.getItem("problemList")).length != 0) {
        let problemList = JSON.parse(sessionStorage.getItem("problemList"));
        this.$store.commit("problemList", [...problemList]);
      }
    }
    sessionStorage.clear();

    if (window.localStorage.getItem("token")) {
      await GetUserInfoById().then((res) => {
        // console.log(res);
        this.$store.commit("userInfo", { ...res.result });
      });
    }

    // else if (JSON.stringify(sessionStorage.getItem("store")) == null) {
    //   await GetUserInfoById().then((res) => {
    //     console.log(res);
    //     this.$store.commit("userInfo", { ...res.result });
    //   });
    // }

    // console.log(window.localStorage.getItem("token"));
    // console.log(this.$store.state.userinfo);
  },
  mounted() {},
};
</script>





<style>
#app {
  /* min-width: 1100px; */
  width: 118.9375rem;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.el-message-box {
  z-index: 99999;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 1rem;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 1rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1rem;
}
</style>
