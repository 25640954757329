<template>
  <div class="livedemo">
    <!-- <WorkHeader @getShow="UserShow" @getMessageShow="MessageShow"></WorkHeader> -->
    <div class="header">
      <div class="header_right">
        <router-link :to="'/'"
          ><img src="../assets/image/index_nav-1(2).png" alt=""
        /></router-link>
      </div>
      <div class="header_left">
        <WorkHeader
          @getShow="UserShow"
          @getMessageShow="MessageShow"
        ></WorkHeader>
      </div>
    </div>

    <div class="main">
      <div class="main_left">
        <div class="gotoWork" name="gotoWorkbench">
          <router-link to="/Workbench?workID=1">
            <i class="el-icon-edit"></i>
            <span>工作台</span>
          </router-link>
        </div>
        <router-link to="/livedemo/livefunlist">
          <a
            @click="
              (curId = 0),
                (Show.myUserInfo = false),
                (Show.mainShow = true),
                (Show.messageShow = false)
            "
            :class="{ cur: curId === 0 }"
          >
            <img
              v-show="curId === 1"
              src="../assets/image/live_template.png"
              alt=""
            />
            <img
              v-show="curId === 0"
              src="../assets/image/live_template_select.png"
              alt=""
            />
            写作模板
          </a></router-link
        >
        <router-link to="/livedemo/livemywoke">
          <a
            @click="
              (curId = 1),
                (Show.myUserInfo = false),
                (Show.mainShow = true),
                (Show.messageShow = false)
            "
            :class="{ cur: curId === 1 }"
          >
            <img
              v-show="curId === 0"
              src="../assets/image/live_work.png"
              alt=""
            />
            <img
              v-show="curId === 1"
              src="../assets/image/live_work_select.png"
              alt=""
            />
            作品管理
          </a>
        </router-link>
      </div>
      <div class="main_right">
        <div class="main_search" v-show="this.inp_show">
          <el-input class="search" placeholder="搜索关键词查询" v-model="input">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="search_btn">
            <span>搜索</span>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import WorkHeader from "../components/workHeader.vue";
export default {
  name: "App",
  components: {
    WorkHeader,
  },
  data() {
    return {
      html: `<P>无数据</P>`,
      curId: 0,
      Show: {
        mainShow: true,
        myUserInfo: true,
        messageShow: false,
      },
      inp_show: true,
      input: "",
      tableData: [
        {
          date: "2016-05-02",
          title: "依然范特西",
          num: 20,
        },
        {
          date: "2016-05-04",
          title: "依然范特西",
          num: 20,
        },
        {
          date: "2016-05-01",
          title: "依然范特西",
          num: 20,
        },
        {
          date: "2016-05-03",
          title: "依然范特西",
          num: 20,
        },
      ],
    };
  },
  methods: {
    UserShow() {
      this.curId = 3;
    },

    MessageShow(val) {
      this.Show = {
        mainShow: false,
        messageShow: val,
      };
      this.curId = 3;
    },
    //
  },
  created() {
    // console.log(this.$route.name);
    switch (this.$route.name) {
      case "userInfo":
        this.curId = 2;
        this.inp_show = false;
        break;
      case "messagecenter":
        this.curId = 2;
        this.inp_show = false;
        break;
      case "livemywoke":
        this.curId = 1;
        this.inp_show = true;
        break;
      case "livefunlist":
        this.curId = 0;
        this.inp_show = true;
        break;
    }
  },
  mounted() {},
  watch: {
    $route: {
      handler(val) {
        switch (val.name) {
          case "userInfo":
            this.curId = 2;
            this.inp_show = false;
            break;
          case "messagecenter":
            this.curId = 2;
            this.inp_show = false;
            break;
          case "livemywoke":
            this.curId = 1;
            this.inp_show = true;
            break;
          case "livefunlist":
            this.curId = 0;
            this.inp_show = true;
            break;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
