<template>
  <div class="login_layer" v-show="loginLayer">
    <div class="login" v-show="loginShow">
      <!-- <div class="login_header header">
        <div class="left">
          <span>登录注册</span>
        </div>
        <div class="right">
          <i @click="LoginClose" class="el-icon-close"></i>
        </div>
      </div> -->

      <!-- app扫码 -->
      <div class="login_App">
        <P class="App_p">
          <img src="../assets/image/index_nav-1(2).png" alt="" />
        </P>
        <div class="App_Acc">
          <img src="../assets/image/appQrcode.png" alt="" />
        </div>
        <!-- <p class="App_login_p">打开书营App扫码登录</p> -->
      </div>
      <!-- 登录方式 -->
      <div class="login_rule">
        <div class="right">
          <i @click="LoginClose" class="el-icon-close"></i>
        </div>

        <div class="login_main">
          <ul>
            <li :class="{ cur: curId === 0 }">
              <a href="javascript:;" @click="curId = 0"> 验证码登录 </a>
            </li>
            <li :class="{ cur: curId === 1 }">
              <a href="javascript:;" @click="curId = 1"> 密码登录 </a>
            </li>
          </ul>
        </div>
        <div class="login_way">
          <div v-show="curId === 0">
            <p class="p_title"></p>

            <div class="login_codeInp">
              <input
                v-model="loginCode.tel"
                type="text"
                placeholder="请输入手机号"
                maxlength="11"
              />
              <div class="code">
                <input
                  style="width: 21.875rem"
                  type="number"
                  placeholder="请输入验证码"
                  maxlength="6"
                  v-model="loginCode.code"
                />
                <p v-show="!codeShow" @click="getCodeToLogin">
                  {{ this.codeText }}
                </p>
                <p v-show="codeShow">{{ this.time }}{{ this.hintText }}</p>
              </div>
            </div>
          </div>
          <div v-show="curId === 1">
            <div class="login_inp">
              <input
                v-model="loginInfo.tel"
                type="number"
                placeholder="请输入手机号"
              />
              <div class="password">
                <input
                  style="width: 21.875rem; margin-bottom: 0"
                  type="password"
                  placeholder="请输入密码"
                  maxlength="20"
                  v-model="loginInfo.password"
                />

                <p>
                  <i
                    @click="clear"
                    v-show="loginInfo.password"
                    class="el-icon-error"
                  ></i>
                </p>
              </div>
            </div>
            <p
              class="pwd_tip"
              style="
                margin-bottom: 1.25rem;
                color: rgb(243, 162, 87);
                text-align: left;
                font-size: .75rem;
              "
            >
              &emsp;密码必须由数字、字母、特殊字符这三个组成
            </p>
          </div>

          <div class="lost_pwd">
            <p @click="lostPwd" v-show="curId == 1">忘记密码？</p>
            <el-checkbox v-model="checked" size="medium">
              <span
                >已阅读并同意
                <!-- <b>《用户协议和隐私条款》</b> -->
              </span>
            </el-checkbox>
            <b>
              <router-link
                class="route"
                target="_blank"
                :to="'/showwin_agrement/showwin_agrement_user'"
                >《用户协议》</router-link
              >
              和
              <router-link
                class="route"
                target="_blank"
                :to="'/showwin_agrement/showwin_agrement_privacy'"
                >《隐私条款》</router-link
              ></b
            >
          </div>
          <div class="login_btn">
            <button @click="loginBtn">登录</button>
            <button @click="registerBtn">用户注册</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 有用户注册 -->
    <div class="registerPwd" v-show="registerShow">
      <div class="login_App">
        <P class="App_p">
          <img src="../assets/image/index_nav-1(2).png" alt="" />
        </P>
        <div class="App_Acc">
          <img src="../assets/image/appQrcode.png" alt="" />
        </div>
        <!-- <p class="App_login_p">打开书营App扫码登录</p> -->
      </div>
      <div class="right">
        <i @click="LoginClose" class="el-icon-close"></i>
      </div>
      <!-- <div class="register_header header">
        <div class="left">
          <span>忘记密码？</span>
        </div>
        <div class="right">
          <i @click="LoginClose" class="el-icon-close"></i>
        </div>
      </div> -->
      <div class="register_main">
        <p class="p_title">
          <i
            style="cursor: pointer"
            class="el-icon-arrow-left"
            @click="back"
          ></i>
          <span>密码登录</span>
        </p>

        <div class="register_inp">
          <input
            v-model="registerInfo.tel"
            type="number"
            placeholder="请输入手机号"
            maxlength="11"
          />

          <div class="code">
            <input
              style="width: 21.875rem"
              type="num"
              placeholder="请输入验证码"
              maxlength="6"
              v-model="registerInfo.code"
            />
            <p v-show="!codeShow" @click="getCodeToRegister">
              {{ this.codeText }}
            </p>
            <p v-show="codeShow">{{ this.time }}{{ this.hintText }}</p>
          </div>

          <div class="password">
            <input
              style="width: 21.875rem; margin-bottom: 0"
              type="password"
              placeholder="请输入新密码"
              maxlength="20"
              v-model="registerInfo.pwd1"
            />
            <p>
              <i
                @click="clear1"
                v-show="registerInfo.pwd1"
                class="el-icon-error"
              ></i>
            </p>
          </div>
          <p
            class="pwd_tip"
            style="
              margin-bottom: 0.5rem;
              color: rgb(243, 162, 87);
              text-align: left;
              font-size: .75rem;
            "
          >
            &emsp;密码必须由数字、字母、特殊字符这三个组成
          </p>
          <div class="password">
            <input
              style="width: 21.875rem"
              type="password"
              placeholder="再次输入密码"
              maxlength="20"
              v-model="registerInfo.pwd2"
            />
            <p>
              <i
                @click="clear2"
                v-show="registerInfo.pwd2"
                class="el-icon-error"
              ></i>
            </p>
          </div>
        </div>
        <div class="register_btn">
          <button @click="save_register">注册</button>
        </div>
      </div>
    </div>
    <!-- 忘记密码 -->
    <div class="lostPwd" v-show="lostShow">
      <div class="login_App">
        <P class="App_p">
          <img src="../assets/image/index_nav-1(2).png" alt="" />
        </P>
        <div class="App_Acc">
          <img src="../assets/image/appQrcode.png" alt="" />
        </div>
        <!-- <p class="App_login_p">打开书营App扫码登录</p> -->
      </div>
      <div class="right">
        <i @click="LoginClose" class="el-icon-close"></i>
      </div>
      <!-- <div class="lost_header header">
        <div class="left">
          <span>忘记密码？</span>
        </div>
        <div class="right">
          <i @click="LoginClose" class="el-icon-close"></i>
        </div>
      </div> -->
      <div class="lost_main">
        <p class="p_title">
          <i
            style="cursor: pointer"
            class="el-icon-arrow-left"
            @click="back"
          ></i>
          <span>密码登录</span>
        </p>

        <div class="lost_inp">
          <input
            v-model="lostInfo.tel"
            type="number"
            placeholder="请输入手机号"
            maxlength="11"
          />

          <div class="code">
            <input
              style="width: 21.875rem"
              type="num"
              placeholder="请输入验证码"
              maxlength="6"
              v-model="lostInfo.code"
            />
            <p v-show="!codeShow" @click="getCodeToLostpwd">
              {{ this.codeText }}
            </p>
            <p v-show="codeShow">{{ this.time }}{{ this.hintText }}</p>
          </div>

          <div class="password">
            <input
              style="width: 21.875rem; margin-bottom: 0"
              type="password"
              placeholder="请输入新密码"
              maxlength="20"
              v-model="lostInfo.pwd1"
            />
            <p>
              <i
                @click="clear1"
                v-show="lostInfo.pwd1"
                class="el-icon-error"
              ></i>
            </p>
          </div>
          <p
            class="pwd_tip"
            style="
              margin-bottom: 0.5rem;
              color: rgb(243, 162, 87);
              text-align: left;
              font-size: .75rem;
            "
          >
            &emsp;密码必须由数字、字母、特殊字符这三个组成
          </p>
          <div class="password">
            <input
              style="width: 21.875rem"
              type="password"
              placeholder="再次输入密码"
              maxlength="20"
              v-model="lostInfo.pwd2"
            />
            <p>
              <i
                @click="clear2"
                v-show="lostInfo.pwd2"
                class="el-icon-error"
              ></i>
            </p>
          </div>
        </div>
        <div class="lost_btn">
          <button @click="save">保存</button>
        </div>
      </div>
    </div>
    <!--微信登录  -->
    <div class="wxLogin" v-show="wxShow">
      <div class="wx_header">
        <div class="left">
          <span>登录注册</span>
        </div>
        <div class="right">
          <i @click="LoginClose" class="el-icon-close"></i>
        </div>
      </div>
      <div class="wx_title">
        <p class="p_title">
          <i
            style="cursor: pointer"
            class="el-icon-arrow-left"
            @click="back"
          ></i>
          <span>返回登录</span>
        </p>
      </div>
      <div class="wx_main">
        <div class="wx_code"></div>
        <div class="wx_p">
          <p>微信扫一扫快捷登录</p>
        </div>
        <div class="wx_clause">
          <el-checkbox v-model="checked" size="medium">
            <span
              >已阅读并同意
              <b>《用户协议和隐私条款》</b>
            </span>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  LoginByPwd,
  LoginByCode,
  Registry,
  GetCode,
  GetUserInfoByPhone,
  ForgetPassword,
} from "../request/api";
export default {
  name: "App",
  props: ["msg"],
  data() {
    return {
      curId: 1,
      //
      loginLayer: this.msg,
      checked: true,
      //
      loginShow: true,
      lostShow: false,
      wxShow: false,
      registerShow: false,
      //登录信息
      loginInfo: {
        tel: "",
        password: "",
      },
      //验证码登录信息
      loginCode: {
        tel: "",
        code: "",
        tokenId: "",
      },
      //注册信息
      registerInfo: {
        tel: "",
        pwd1: "",
        pwd2: "",
        code: "",
        tokenId: "",
      },
      //忘记密码信息
      lostInfo: {
        tel: "",
        pwd1: "",
        pwd2: "",
        code: "",
        tokenId: "",
      },

      //验证码倒计时
      timer: null,
      time: 60,
      codeText: "获取验证码",
      hintText: "秒后重新发送",
      codeShow: false,

      //正则验证
      //手机号正则 + 密码正则
      reg_tel:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      reg_pwd:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$/,
      //最少八个最多十个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符：
    };
  },
  methods: {
    //登录窗口退出
    LoginClose() {
      this.loginLayer = false;
      this.loginShow = true;
      this.lostShow = false;
      this.wxShow = false;
      this.registerShow = false;
      //数据归零
      Object.keys(this.loginCode).forEach((key) => (this.loginCode[key] = ""));
      Object.keys(this.loginInfo).forEach((key) => (this.loginInfo[key] = ""));
      Object.keys(this.registerInfo).forEach(
        (key) => (this.registerInfo[key] = "")
      );
      this.codeText = "获取验证码";
      this.$emit("getShow", this.loginLayer);
    },
    //密码一键清除按钮
    clear() {
      this.loginInfo.password = "";
    },
    //返回登录主页
    back() {
      this.lostShow = false;
      this.loginShow = true;
      this.wxShow = false;
      this.registerShow = false;
      Object.keys(this.registerInfo).forEach(
        (key) => (this.registerInfo[key] = "")
      );
      Object.keys(this.lostInfo).forEach((key) => (this.lostInfo[key] = ""));
    },
    //注册账号两个清除按钮
    clear1() {
      this.registerInfo.pwd1 = "";
    },
    clear2() {
      this.registerInfo.pwd2 = "";
    },
    //忘记密码保存按钮
    save() {
      // console.log(this.lostInfo);
      if (this.reg_tel.test(this.lostInfo.tel)) {
        if (this.lostInfo.code && this.lostInfo.tokenId) {
          // alert("验证码通过");
          if (this.lostInfo.pwd1 == this.lostInfo.pwd2) {
            if (this.reg_pwd.test(this.lostInfo.pwd1)) {
              // alert("密码通过");
              let info = {
                phone: this.lostInfo.tel,
                code: this.lostInfo.code,
                tokenId: this.lostInfo.tokenId,
                event: this.lostInfo.event,
                password: this.lostInfo.pwd1,
              };
              // console.log(info);
              ForgetPassword({ ...info }).then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success",
                    offset: 100,
                  });
                  Object.keys(this.lostInfo).forEach(
                    (key) => (this.lostInfo[key] = "")
                  );
                  this.lostShow = false;
                  this.loginShow = true;
                }
              });
            } else {
              this.$message({
                message:
                  "密码必须由数字、字母、特殊字符这三个组成!长度为8 ~ 20",
                type: "warning",
                offset: 100,
              });
            }
          } else {
            this.$message({
              message: "两次密码不一致",
              type: "warning",
              offset: 100,
            });
          }
        } else {
          this.$message({
            message: "验证码不匹配",
            type: "warning",
            offset: 100,
          });
        }
      } else {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
          offset: 100,
        });
      }
    },
    //获取验证码
    //注册
    async getCodeToRegister() {
      /**
       * TODO:1.正则验证手机号，确定验证信息 */
      if (this.reg_tel.test(this.registerInfo.tel)) {
        let reg_info = {
          phone: this.registerInfo.tel,
          event: "Register",
        };
        // console.log(reg_info);
        this.codeShow = true;
        this.timer = setInterval(() => {
          this.time--;
          if (this.time < 1) {
            clearInterval(this.timer);
            this.codeText = "重新发送";
            this.time = 60;
            this.codeShow = false;
          }
        }, 1000);
        /**
         * TODO:2.获取验证码 */
        await GetCode({ ...reg_info }).then((res) => {
          //获取验证码生成成功后的tokenId
          // console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "验证码已发送",
              type: "success",
              offset: 100,
            });
            this.registerInfo.tokenId = res.tokenId; //拿到验证码tokenId
          } else {
            this.$message({
              message: res.message,
              type: "warning",
              offset: 100,
            });
          }
        });
      } else {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
          offset: 100,
        });
      }
    },
    //找回密码 / 重置密码
    async getCodeToLostpwd() {
      // this.lostShow = false;
      // this.loginShow = true;
      if (this.reg_tel.test(this.lostInfo.tel)) {
        // alert("手机号通过");
        let lost_info = {
          event: "lostPwd",
          phone: this.lostInfo.tel,
        };
        // console.log(lost_info);
        this.codeShow = true;
        this.timer = setInterval(() => {
          this.time--;
          if (this.time < 1) {
            clearInterval(this.timer);
            this.codeText = "重新发送";
            this.time = 60;
            this.codeShow = false;
          }
        }, 1000);
        /**
         * TODO:2.获取验证码 */
        await GetCode({ ...lost_info }).then((res) => {
          //获取验证码生成成功后的tokenId
          // console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "验证码已发送",
              type: "success",
              offset: 100,
            });
            this.lostInfo.tokenId = res.tokenId;
            this.lostInfo.event = lost_info.event; //拿到验证码tokenId
          } else {
            this.$message({
              message: res.message,
              type: "warning",
              offset: 100,
            });
          }
        });
      } else {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
          offset: 100,
        });
      }
    },
    //验证码登录
    async getCodeToLogin() {
      if (this.reg_tel.test(this.loginCode.tel)) {
        let reg_info = {
          phone: this.loginCode.tel,
          event: "loginByCode",
        };
        await GetCode({ ...reg_info }).then((res) => {
          //获取验证码生成成功后的tokenId
          // console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "验证码已发送",
              type: "success",
              offset: 100,
            });
            this.loginCode.tokenId = res.tokenId;
            this.codeShow = true;
            this.timer = setInterval(() => {
              this.time--;
              if (this.time < 1) {
                clearInterval(this.timer);
                this.codeText = "重新发送";
                this.time = 60;
                this.codeShow = false;
              }
            }, 1000);

            // console.log(this.loginCode);
          } else {
            this.$message({
              message: res.message,
              type: "success",
              offset: 100,
            });
          }
        });
      } else {
        this.$message({
          message: "请输入正确手机号",
          type: "warning",
          offset: 100,
        });
      }
    },

    //点击进入忘记密码界面
    lostPwd() {
      this.loginShow = false;
      this.lostShow = true;
    },
    //微信登录界面 => 未启用
    wxLogin() {
      this.loginShow = false;
      this.wxShow = true;
    },
    registerBtn() {
      this.loginShow = false;
      this.registerShow = true;
    },

    //验证码新用户注册
    async save_register() {
      // 窗口跳转
      // this.registerShow = false;
      // this.loginShow = true;

      //注册手机号和密码正则验证
      let reg_tel = this.reg_tel.test(this.registerInfo.tel);
      // let reg_pwd = this.reg_pwd.test(this.registerInfo.tel);
      //两次密码是否相同
      let pwd_verify =
        this.registerInfo.pwd1 == this.registerInfo.pwd2 ? true : false;

      let reg_pwd = this.reg_pwd.test(this.registerInfo.pwd1);
      //1.判断手机号是否合规
      //2.判断是否输入验证码
      //3.判断两次密码是否输入正确且密码符合正则
      if (reg_tel) {
        if (this.registerInfo.code && this.registerInfo.tokenId) {
          if (reg_tel && pwd_verify && reg_pwd) {
            let register = {
              code: this.registerInfo.code,
              event: "Register",
              password: this.registerInfo.pwd1,
              tokenId: this.registerInfo.tokenId,
              phone: this.registerInfo.tel,
            };
            // console.log("注册信息", register);
            await Registry({ ...register }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success",
                  offset: 100,
                });
                this.lostShow = false;
                this.loginShow = true;
                this.wxShow = false;
                this.registerShow = false;
                Object.keys(this.registerInfo).forEach(
                  (key) => (this.registerInfo[key] = "")
                );
              } else {
                this.$message({
                  message: res.message,
                  type: "warning",
                  offset: 100,
                });
              }
            });
          } else {
            this.$message({
              message: "两次输入的密码不一致或者密码不符合规则",
              type: "warning",
              offset: 100,
            });
          }
        } else {
          this.$message({
            message: "验证码不正确!",
            type: "warning",
            offset: 100,
          });
        }
      } else {
        this.$message({
          message: "请输入正确的手机号!",
          type: "warning",
          offset: 100,
        });
      }

      // console.log(this.registerInfo);

      //
    },
    //密码登录 + 验证码登录
    async loginBtn() {
      switch (this.curId) {
        case 1: //密码登录
          // console.log(this.loginInfo);
          //1.判断勾选协议
          if (this.checked) {
            if (this.reg_tel.test(this.loginInfo.tel)) {
              if (this.reg_pwd.test(this.loginInfo.password)) {
                let LoginInfo = {
                  phone: this.loginInfo.tel,
                  password: this.loginInfo.password,
                };
                //登录请求
                await LoginByPwd({ ...LoginInfo }).then((res) => {
                  // console.log(res);
                  if (res.code == 200) {
                    // console.log(res);
                    this.loginLayer = false;
                    this.loginShow = true;
                    this.lostShow = false;
                    window.localStorage.setItem("token", res.result.token);
                    /**
                     * TODO:登陆成功之后要进行的操作
                     * 1.根据用户手机号拿到用户信息数据
                     * 2.根据用户Id拿到用户第一页作品数据
                     * 3.把这两个全部存入vuex
                     * 4.删除输入框信息
                     */
                    Object.keys(this.loginInfo).forEach(
                      (i) => (this.loginInfo[i] = "")
                    );
                    GetUserInfoByPhone(LoginInfo.phone).then((res) => {
                      // console.log(res);
                      if (res.code == 200) {
                        let userInfo = {
                          ...res.result,
                        };
                        // window.localStorage.setItem(
                        //   "userInfo",
                        //   JSON.stringify(userInfo)
                        // );
                        //存全局 + 存vuex
                        this.$store.commit("userInfo", { ...userInfo });
                        // this.$store.commit("userInfo", [...res.result]);
                        this.$emit("userInfo", userInfo);
                        this.loginLayer = false;
                        this.$message({
                          message: "登录成功",
                          type: "success",
                          offset: 100,
                        });
                        // this.$router.go(0);
                      }
                    });
                  } else {
                    this.$message({
                      message: res.message + "注意输入字母大小写",
                      type: "warning",
                      offset: 100,
                    });
                  }
                });
              } else {
                this.$message({
                  message:
                    "密码是一个大写字母或一个小写字母，一个数字以及一个特殊字符组成，长度8-20",
                  type: "warning",
                  offset: 100,
                });
              }
            } else {
              this.$message({
                message: "请填写正确的手机号!",
                type: "warning",
                offset: 100,
              });
            }
          } else {
            this.$message({
              message: "登录前请勾选《用户协议和隐私条款》",
              type: "warning",
              offset: 100,
            });
          }

          break;
        case 0: //验证码登录
          /**
           * TODO: 1.判断是否勾选协议 */
          if (this.checked) {
            /**
             * TODO: 2.判断手机号符合正则 */
            if (this.reg_tel.test(this.loginCode.tel)) {
              // console.log(this.loginCode);
              /**
               * TODO: 3.判断验证码和token */
              if (this.loginCode.code && this.loginCode.tokenId) {
                let loginCode = {
                  phone: this.loginCode.tel,
                  event: "loginByCode",
                  code: this.loginCode.code,
                  tokenId: this.loginCode.tokenId,
                };
                // console.log(loginCode);
                /**
                 * TODO: 4.传入数据进行登录 */
                await LoginByCode({ ...loginCode }).then((res) => {
                  // console.log(res);
                  if (res.code == 200) {
                    this.loginLayer = false;
                    this.loginShow = true;
                    this.lostShow = false;
                    window.localStorage.setItem("token", res.result.token);
                    Object.keys(this.loginCode).forEach(
                      (i) => (this.loginCode[i] = "")
                    );
                    // window.localStorage.setItem(
                    //   "userInfo",
                    //   JSON.stringify(this.$store.state.userInfo)
                    // );
                    // window.localStorage.setItem(
                    //   "token",
                    //   JSON.stringify(res.result.token)
                    // );
                    // this.$emit("userInfo", this.$store.state.userInfo);
                    //

                    GetUserInfoByPhone(loginCode.phone).then((res) => {
                      // console.log(res);
                      if (res.code == 200) {
                        let userInfo = {
                          ...res.result,
                        };
                        // window.localStorage.setItem(
                        //   "userInfo",
                        //   JSON.stringify(userInfo)
                        // );
                        //存全局 + 存vuex
                        this.$store.commit("userInfo", { ...userInfo });
                        // this.$store.commit("userInfo", [...res.result]);
                        this.$emit("userInfo", userInfo);
                        this.$message({
                          message: "登录成功",
                          type: "success",
                          offset: 100,
                        });
                        // this.$router.go(0);
                      }
                    });
                    // .then(() => {
                    //   console.log("查询成功 => 查询作品");
                    // });
                  } else {
                    this.$message({
                      message: res.message,
                      type: "warning",
                      offset: 100,
                    });
                  }
                });
              } else {
                this.$message({
                  message: "验证码输入错误",
                  type: "warning",
                  offset: 100,
                });
              }
            } else {
              this.$message({
                message: "请输入正确是手机号!",
                type: "warning",
                offset: 100,
              });
            }
          } else {
            this.$message({
              message: "登录前请勾选《用户协议和隐私条款》",
              type: "warning",
              offset: 100,
            });
          }
          break;
      }
    },

    KeyDown(e) {
      if (this.loginLayer) {
        if (e.key === "Enter" || e.keyCode === 13) {
          if (!e.repeat) {
            return this.loginBtn();
          } else {
            return;
          }
        }
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("keydown", this.KeyDown, true);
  },
  watch: {
    msg(newMsg) {
      // newVal 为新值， oldVal 为旧值
      this.loginLayer = newMsg;
    },
  },
};
</script>
<style lang='scss' scoped>
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  // color: red;
  font-size: 1rem;
  
}
::-moz-placeholder {
  /* Firefox 19+ */
  // color: red;
  font-size: 1rem;
}
:-ms-input-placeholder {
  /* IE 10+ */
  // color: red;
  font-size: 1rem;
}
:-moz-placeholder {
  /* Firefox 18- */
  // color: red;
  font-size: 1rem;
}
.login_layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  height: 100%;
  width: 100%;
  background: hsla(0, 0%, 0%, 0.3);
  overflow: hidden;

  .wxLogin {
    width: 40.625rem;
    height: 40.625rem;
    background: #ffffff;
    border-radius: 2rem;
    margin: 13.5rem auto;
    overflow: hidden;
    .wx_header {
      width: 100%;
      height: 5rem;
      background-color: #4673ff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 1.25rem 2.5rem;
      .left {
        font-size: 1.75rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 2.5rem;
      }
      .right {
        i {
          font-size: 2.8125rem;
          color: #fff;
        }
      }
    }
    .wx_title {
      margin: 0 auto;
      width: 25rem;
      margin-top: 2.8125rem;
      .p_title {
        display: flex;
        margin-bottom: 2rem;

        span {
          font-size: 1.25rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191f33;
          line-height: 1.75rem;
        }
        i {
          margin-right: 0.9375rem;
          font-weight: 900;
          font-size: 2rem;
        }
      }
    }
    .wx_main {
      width: 25rem;
      margin: 2.75rem auto;
      .wx_code {
        width: 18rem;
        height: 18rem;
        margin: 0 auto;
        background-image: url("../assets/image/微信二维码底.png");
        background-size: 18rem 18rem;
        box-sizing: border-box;
        padding: 0.875rem;
      }
      .wx_p {
        margin-top: 0.75rem;
        p {
          font-size: 1.5rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #191f33;
          line-height: 2.0625rem;
        }
      }
      .wx_clause {
        margin: 2.0625rem 0 0 0;
        span {
          color: #191f33;
          b {
            color: #4673ff;
          }
        }
      }
    }
  }

  .lostPwd {
    width: 61.25rem;
    height: 35rem;
    background: #ffffff;
    border-radius: 2rem;
    margin: 13.5rem auto;
    overflow: hidden;
    display: flex;
    position: relative;

    .login_App {
      height: 25.625rem;
      width: 26.5625rem;
      // background-color: red;
      box-sizing: border-box;
      margin: 4.5rem 0;
      border-right: 0.125rem solid #ebedf2;
      .App_p {
        margin-top: 1.875rem;
        width: 88px;
        height: 32px;
        image{
          width: 100%;
          height: 100%;
        }
      }
      .App_Acc {
        width: 16.25rem;
        height: 16.25rem;
        margin: 0.875rem auto;
        // background-color: red;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      .App_login_p {
        font-size: 1.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
      }
    }
    .right {
      position: absolute;
      left: 56.5rem;
      top: 1.5rem;
      i {
        color: #a4abc0;
        font-weight: 900;
        font-size: 2.8125rem;
      }
    }

    .lost_header {
      width: 100%;
      height: 5rem;
      background-color: #4673ff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 1.25rem 2.5rem;
    }
    .lost_main {
      width: 25rem;
      margin: 0 auto;
      // background-color: red;
      margin-top: 4.375rem;
      .p_title {
        display: flex;
        margin-bottom: 2rem;
        span {
          font-size: 1.25rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191f33;
          line-height: 1.75rem;
        }
        i {
          margin-right: 0.9375rem;
          font-weight: 900;
          font-size: 2rem;
        }
      }
      .lost_inp {
        input {
          width: 25rem;
          height: 3.75rem;
          background: #f5f6fc;
          border-radius: 0.25rem;
          outline: none;
          box-sizing: border-box;
          padding: 1rem 1.3125rem;
          margin-bottom: 1.25rem;
        }
        .password {
          margin-top: 0;
          display: flex;
          p {
            width: 3.125rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            i {
              font-size: 1.5rem;
              color: #a4abc0;
              line-height: 3.75rem;
              cursor: pointer;
            }
          }
        }
        .code {
          display: flex;
          margin-top: 0;
          p {
            width: 15rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            color: #4673ff;
            line-height: 3.75rem;
            font-size: 1.25rem;
            font-weight: 600;
            cursor: pointer;
          }
          input {
            width: 10rem;
          }
        }
      }
      .lost_btn {
        button {
          width: 25rem;
          height: 3.75rem;
          background-color: #4673ff;
          font-size: 1.25rem;
          color: #fff;
          font-weight: 600;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .registerPwd {
    width: 61.25rem;
    height: 35rem;
    background: #ffffff;
    border-radius: 2rem;
    margin: 13.5rem auto;
    overflow: hidden;
    display: flex;
    position: relative;

    .login_App {
      height: 25.625rem;
      width: 26.5625rem;
      // background-color: red;
      box-sizing: border-box;
      margin: 4.5rem 0;
      border-right: 0.125rem solid #ebedf2;
      .App_p {
        margin-top: 1.875rem;
      }
      .App_Acc {
        width: 16.25rem;
        height: 16.25rem;
        margin: 0.875rem auto;
        // background-color: red;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      .App_login_p {
        font-size: 1.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
      }
    }
    .right {
      position: absolute;
      left: 56.5rem;
      top: 1.5rem;
      i {
        color: #a4abc0;
        font-weight: 900;
        font-size: 2.8125rem;
      }
    }

    .register_header {
      width: 100%;
      height: 5rem;
      background-color: #4673ff;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 1.25rem 2.5rem;
    }
    .register_main {
      width: 25rem;
      margin: 0 auto;
      // background-color: red;
      margin-top: 4.375rem;
      .p_title {
        display: flex;
        margin-bottom: 2rem;
        span {
          font-size: 1.25rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191f33;
          line-height: 1.75rem;
        }
        i {
          margin-right: 0.9375rem;
          font-weight: 900;
          font-size: 2rem;
        }
      }
      .register_inp {
        input {
          width: 25rem;
          height: 3.75rem;
          background: #f5f6fc;
          border-radius: 0.25rem;
          outline: none;
          box-sizing: border-box;
          padding: 1rem 1.3125rem;
          margin-bottom: 1.25rem;
        }
        .password {
          margin-top: 0;
          display: flex;
          p {
            width: 3.125rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            i {
              font-size: 1.5rem;
              color: #a4abc0;
              line-height: 3.75rem;
              cursor: pointer;
            }
          }
        }
        .code {
          display: flex;
          margin-top: 0;
          p {
            width: 15rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            color: #4673ff;
            line-height: 3.75rem;
            font-size: 1.25rem;
            font-weight: 600;
            cursor: pointer;
          }
          input {
            width: 10rem;
          }
        }
      }
      .register_btn {
        button {
          width: 25rem;
          height: 3.75rem;
          background-color: #4673ff;
          font-size: 1.25rem;
          color: #fff;
          font-weight: 600;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .header {
    width: 100%;
    height: 5rem;
    background-color: #4673ff;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.25rem 2.5rem;
    .left {
      font-size: 1.75rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 2.5rem;
    }
    .right {
      i {
        font-size: 2.8125rem;
        color: #fff;
      }
    }
  }

  .login {
    width: 61.25rem;
    height: 35rem;
    background: #ffffff;
    border-radius: 2rem;
    margin: 13.5rem auto;
    overflow: hidden;
    display: flex;
    .login_App {
      height: 25.625rem;
      width: 26.5625rem;
      // background-color: red;
      box-sizing: border-box;
      margin: 4.5rem 0;
      border-right: 0.125rem solid #ebedf2;
      .App_p {
        margin-top: 1.875rem;
      }
      .App_Acc {
        width: 16.25rem;
        height: 16.25rem;
        margin: 0.875rem auto;
        // background-color: red;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      .App_login_p {
        font-size: 1.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
      }
    }

    .login_rule {
      position: relative;
      .right {
        position: absolute;
        left: 30rem;
        top: 1.5rem;
        i {
          color: #a4abc0;
          font-weight: 900;
          font-size: 2.8125rem;
        }
      }
    }

    .login_main {
      width: 100%;
      height: 2.5rem;
      box-sizing: border-box;
      padding: 0 7.8125rem;
      margin-top: 4.0625rem;
      li {
        width: 9.125rem;
        height: 2rem;
        font-size: 1.25rem;
        font-weight: 400;
        color: #191f33;
        line-height: 3.125rem;
      }
      .cur {
        font-size: 1.75rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 2.5rem;
      }
    }
    .login_way {
      div:first-child {
        width: 25rem;
        margin: 0 auto;
      }
      div:nth-child(2n) {
        width: 25rem;
        margin: 0 auto;
        margin-top: 2.5rem;
      }
      .login_inp {
        display: flex;
        width: 25rem;
        margin: 0 auto;
        flex-wrap: wrap;
        .password {
          margin-top: 0;
          display: flex;
          p {
            width: 3.125rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            i {
              font-size: 1.5rem;
              color: #a4abc0;
              line-height: 3.75rem;
              cursor: pointer;
            }
          }
        }
      }

      .login_codeInp {
        .code {
          display: flex;
          margin-top: 0;
          p {
            width: 15rem;
            height: 3.75rem;
            background-color: #f5f6fc;
            border-radius: 0.25rem;
            color: #4673ff;
            line-height: 3.75rem;
            font-size: 1.25rem;
            font-weight: 600;
            cursor: pointer;
          }
          input {
            width: 10rem;
          }
        }
      }
      input {
        width: 25rem;
        height: 3.75rem;
        background: #f5f6fc;
        border-radius: 0.25rem;
        outline: none;
        box-sizing: border-box;
        padding: 1rem 1.3125rem;
        margin-bottom: 1.25rem;
      }
      .p_title {
        width: 25rem;
        margin: 0 auto;
        text-align: left;
        font-size: 1rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffba42;
        line-height: 1.375rem;
        padding-left: 3.625rem;
      }
    }

    .lost_pwd {
      display: flex;
      // width: 25rem;
      margin: 0 auto;
      // justify-content: center;
      box-sizing: border-box;
      padding: 0 0 0 4.5125rem;
      p {
        cursor: pointer;
        margin-right: 2rem;
      }
      span {
        color: #191f33;
      }
      b {
        cursor: pointer;
        .route {
          color: #4673ff;
        }
      }
    }

    .login_btn {
      margin: 0 auto;
      display: flex;
      width: 25rem;
      flex-wrap: wrap;
      margin-top: 1.75rem !important;
      button {
        margin-bottom: 1.25rem;
        width: 100%;
        height: 3.75rem;
        background-color: #4673ff;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        color: #fff;
        font-weight: 600;
      }
      // button:nth-child(2n) {
      //   background-image: url("../assets/image/icon/微信登录.png");
      //   background-size: 25rem 3.75rem;
      // }
      button:nth-child(2n) {
        background-color: #fff;
        border: 0.0625rem solid #4673ff;
        color: #4673ff;
      }
    }
  }
}
</style>
