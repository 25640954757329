import axios from "axios";

// axios.defaults.baseURL = "http://localhost:2345";//请求数据的时候 不加前缀 默认

//开发环境

// axios.defaults.baseURL = "http://47.103.40.97:8080"; //43.143.197.79
//  测试环境
// axios.defaults.baseURL = "https://wai.showwin.net/api"; //43.143.197.79

//线上环境
axios.defaults.baseURL = "https://www.showwin.net/api";//43.143.197.79
export default axios;
