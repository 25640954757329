import Vue from "vue";
import scroll from "vue-seamless-scroll";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Footer from "./components/footer.vue";
import ElementUI from "element-ui";
// import { Button, Select } from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/index.scss";
import "./assets/css/livedemo.scss";
import "./assets/css/workbench.scss";
import "./assets/css/membercenter.scss";
import "./assets/css/common.scss";
import "./assets/css/propre.scss";
import "./assets/css/helpcenter.scss";
import "lib-flexible/flexible.js";
import tool from "./utils/tool";
//
import "./components/helpInfo/helpInfo.scss";

// Vue.component('Footer',Footer)
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(scroll);
Vue.use(tool);

// Vue.use(Button)
// Vue.use(Select)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
