<template>
  <div v-html="apply"></div>
</template>
<script>
export default {
  name: "App",
  data() {
    return { apply: "" };
  },
  methods: {},
  created() {},
  mounted() {
    let form = this.$route.query.htmls;
    // console.log(form);
    this.apply = form;
    this.$nextTick(() => {
      document.forms[0].submit();
    });
  },
};
</script>
<style lang='scss' scoped>
</style>
