<template>
  <div class="index" ref="index">
    <Side v-show="this.sideShow"></Side>
    <!-- 头部 -->
    <div class="header">
      <div class="nav">
        <div class="nav_header">
          <div class="mid">
            <div class="mid_left">
              <img src="../assets/image/index_nav-1.png" alt="" />
            </div>
            <ul>
              <!-- <li>客户端下载</li> -->
              <router-link :to="'/livedemo/livefunlist'">
                <li>立即创作</li>
              </router-link>
              <router-link :to="'/productpresentation'">
                <li>产品介绍</li>
              </router-link>
              <li class="fun_business_cooperation">
                商务合作
                <div class="fun_one">
                  <img src="../assets/image/QRcode/service.png" alt="" />
                </div>
              </li>
              <li class="fun_miniapp">
                免费体验
                <div class="fun_two">
                  <img src="../assets/image/QRcode/applet.jpg" alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <img
              v-if="!this.userInfo"
              src="../assets/image/index_nav-2.png"
              alt=""
              style="margin-top: 1rem; width: 5rem; height: 2.25rem"
              @click="show_login"
            />
            <WorkHeader v-if="this.userInfo" :isFixed="isFixed"></WorkHeader>
          </div>
        </div>
      </div>
      <div class="nav" :class="{ is_fixed: isFixed }" v-show="isFixed">
        <div class="nav_header">
          <div class="mid">
            <div class="mid_left">
              <img src="../assets/image/index_nav-1(2).png" alt="" />
            </div>
            <ul>
              <!-- <li>客户端下载</li> -->
              <router-link :to="'/livedemo/livefunlist'">
                <li>立即创作</li>
              </router-link>
              <router-link :to="'/productpresentation'">
                <li>产品介绍</li>
              </router-link>
              <li class="fun_business_cooperation">
                商务合作
                <div class="fun_one">
                  <img src="../assets/image/QRcode/service.png" alt="" />
                </div>
              </li>
              <li class="fun_miniapp">
                在线体验
                <div class="fun_two">
                  <img src="../assets/image/QRcode/applet.jpg" alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <img
              v-if="!this.userInfo"
              src="../assets/image/index_nav-2.png"
              alt=""
              style="margin-top: 1rem; width: 5rem; height: 2.25rem"
              @click="show_login"
            />
            <WorkHeader v-if="this.userInfo" :isFixed="isFixed"></WorkHeader>
          </div>
        </div>
      </div>
      <div class="title">
        <div class="title_t">
          <p>书营AI写作星球</p>
        </div>
        <div class="title_sp" v-show="screenWidth > 1090 ? true : false">
          <span
            >书营AI写作星球是由艺阁集团开发的内容智能续写系统。用户输入关键词，自动生成高质量文章，适用于抖音、小红书、微信公众号、电商文案、百度、知乎、豆瓣等文案的写作，大大提高了创造者的写作效率，也为企业降低了大量人力成本。</span
          >
        </div>
      </div>
      <div class="Btn">
        <div class="btn_main">
          <div class="btn download">
            <p v-on:mouseleave="app_leave" v-on:mouseenter="app_enter">
              App下载
            </p>
            <div class="qcCode" v-show="this.codeShow">
              <div class="code">
                <img src="../assets/image/downLoad.png" alt="">
              </div>
              <div class="code_title">Android 扫码直接下载</div>
            </div>
          </div>
          <div class="btn online">
            <router-link :to="'/livedemo/livefunlist'">
              <p>立即创作</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 功能 -->
    <div class="fun">
      <div class="title">
        <div class="title_p">
          <p>书营AI的功能是如此强大</p>
        </div>
      </div>
      <div class="main">
        <div class="fun_one fc">
          <img src="../assets/image/index_fun-1.png" alt="" />
          <p>放下双手，马上成神</p>
          <span
            >在创作界面输入一段文字，点击AI来帮写，便立刻出现续写方案，千字文稿一键生成，从此解决头秃烦恼</span
          >
        </div>
        <div class="fun_two fc">
          <img src="../assets/image/index_fun-2.png" alt="" />
          <p>多样风格，写你所想</p>
          <span
            >小红书、公众号、抖音、百度、知乎、淘宝、豆瓣，新媒体er必备良药</span
          >
        </div>
        <div class="fun_three fc">
          <img src="../assets/image/index_fun-3.png" alt="" />
          <p>自建语料库</p>
          <span
            >根据你此刻的需要来建立专门的语料库。例如，你是小红书文案，那么你可以围绕「小红书」建立语料库</span
          >
        </div>
        <div class="fun_four fc">
          <img src="../assets/image/index_fun-4.png" alt="" />
          <p>VIP云储存空间</p>
          <span>文档实时同步云端，不再担心数据丢失</span>
        </div>
      </div>
    </div>
    <!-- 场景 -->
    <div class="sence">
      <div class="title">
        <p>轻松应对多种场景</p>
      </div>
      <div class="sence_one senWin">
        <div class="win_img">
          <img src="../assets/image/image.png" alt="" />
        </div>
        <div class="win_sp">
          <p class="p_img">
            <img src="../assets/image/index_sence-1.png" alt="" />
          </p>
          <p class="p_title">
            <span>自媒体写作救星</span><br />
            <span class="sp"
              >靠输出吃饭，时间就是金钱，效率就是生命，一键生成，高频更新，轻松省去2小时</span
            >
          </p>
        </div>
      </div>
      <div class="sence_two senWin">
        <div class="win_sp">
          <p class="p_img">
            <img src="../assets/image/index-sence-2.png" alt="" />
          </p>
          <p class="p_title">
            <span>新媒体企业 / 自媒体工作室</span><br />
            <span class="sp"
              >主流平台需要大量的精细文案，AI写作低成本、大批量，为企业发展降本增效</span
            >
          </p>
        </div>
        <div class="win_img">
          <img src="../assets/image/image_03.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 方案 -->
    <div class="scheme">
      <div class="title">
        <p>新媒体内容一站式解决方案</p>
      </div>
      <div class="main">
        <div class="title">
          <p>热门方案</p>
        </div>
        <div class="scheme_list">
          <ul>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-redbook.png" alt="" />
              </p>
              <p>小红书</p>
              <p>标题解决方案</p>
              <p>正文解决方案</p>
              <p>图文解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-douyin.png" alt="" />
              </p>
              <p>抖音</p>
              <p>标题解决方案</p>
              <p>脚本解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-wechat.png" alt="" />
              </p>
              <p>微信公众号</p>
              <p>标题解决方案</p>
              <p>正文解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-taobao.png" alt="" />
              </p>
              <p>电商文案</p>
              <p>图文解决方案</p>

              <p>标题解决方案</p>
              <p>好评解决方案</p>
              <p>详情页解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-baidu.png" alt="" />
              </p>
              <p>百度</p>
              <p>标题解决方案</p>
              <p>文章解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-zhihu.png" alt="" />
              </p>
              <p>知乎</p>
              <p>问题解决方案</p>
              <p>回答解决方案</p>
            </li>
            <li>
              <p class="p_img">
                <img src="../assets/image/index_scheme-douban.png" alt="" />
              </p>
              <p>豆瓣</p>
              <p>书评解决方案</p>
              <p>影评解决方案</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="scheme_advantage">
      <div class="title">方案优势</div>
      <div class="main">
        <div class="advantage_one adv">
          <p class="p_img">
            <img src="../assets/image/index_illustration_01.png" alt="" />
          </p>
          <p>AI生成学习内容广泛</p>
          <br />
          <span style="width: 21.5rem"
            >人类的知识储备和精力是有限的，AI训练语料的最低要求已经超过正常人的阅读量。AI还能持续抓取网络上的新内容，跟进新的热点、事件和词汇。</span
          >
        </div>
        <div class="advantage_two adv">
          <p class="p_img">
            <img src="../assets/image/index_illustration_02.png" alt="" />
          </p>
          <p>创作速度快</p>
          <br />
          <span style="width: 23.1875rem"
            >人类搜索阅读、整理数据、构思、最终输出内容，每个环节都需要付出相当大的时间来思考和梳理，而AI能实时抓取最新数据并作出分析，其全面性和速度远远超出人工。</span
          >
        </div>
        <div class="advantage_three adv">
          <p class="p_img">
            <img src="../assets/image/index_illustration_03.png" alt="" />
          </p>
          <p>不需要休息</p>
          <br />
          <span style="width: 18rem"
            >AI不会疲惫，连续工作的状态下也能保证高质量的输出，可以节省大量人力</span
          >
        </div>
      </div>
    </div>
    <!-- 企业服务 -->
    <div class="enterprise_service">
      <div class="title">
        <p>企业服务</p>
      </div>
      <div class="main">
        <div class="left">
          <img src="../assets/image/index_serve-01.png" alt="" />
          <img src="../assets/image/index_serve-04.png" alt="" />
        </div>
        <div class="mid">
          <img src="../assets/image/ShowwinAI.png" alt="" />
          <img src="../assets/image/index_serve-02.png" alt="" />
        </div>
        <div class="right">
          <img src="../assets/image/index_serve-03.png" alt="" />
          <img src="../assets/image/index_serve-05.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 客户窗口 -->
    <div class="win_client">
      <div class="title">
        <p>客户的口碑就是一切</p>
        <!-- 两边模糊覆盖层-->
        <div class="maskLayer1 mask"></div>
        <div class="maskLayer2 mask"></div>
      </div>
      <div class="main">
        <div class="cli_header">
          <vue-seamless-scroll
            :data="listData2"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData2"
                :key="index"
              >
                <div class="item-main">
                  <div class="left">
                    <div class="user">
                      <img :src="item.avatarUrl" alt="" />
                    </div>
                    <div class="username">{{ item.user }}</div>
                    <div class="userpost">{{ item.userpost }}</div>
                  </div>
                  <div class="right">
                    <div class="eva">
                      <el-rate v-model="value1" disabled></el-rate>
                    </div>
                    <p class="eva_sp">
                      {{ item.eva_sp }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <div class="cli_footer">
          <vue-seamless-scroll
            :data="listData3"
            :class-option="classOption2"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData3"
                :key="index"
              >
                <div class="item-main">
                  <div class="left">
                    <div class="user">
                      <img :src="item.avatarUrl" alt="" />
                    </div>
                    <div class="username">{{ item.user }}</div>
                    <div class="userpost">{{ item.userpost }}</div>
                  </div>
                  <div class="right">
                    <div class="eva">
                      <el-rate v-model="value1" disabled></el-rate>
                    </div>
                    <p class="eva_sp">
                      {{ item.eva_sp }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="top">
        <div class="left">
          <img src="../assets/image/index_footer-1.png" alt="" />
        </div>
        <div class="right">
          <ul>
            <li>
              <!-- <a href="www.ego1999.com"> 关于我们</a> -->
              关于我们
            </li>

            <router-link :to="'/helpcenter'">
              <li>帮助中心</li>
            </router-link>

            <li>
              <p>联系我们</p>
              <!-- <p>电话:</p> -->
              <p class="phone" id="info">电话 :0571-88055158</p>
              <!-- <p>邮箱:</p> -->
              <p class="eMail" id="info">邮箱 :Markting@ego1999.com</p>
              <!-- <p>地址:</p> -->
              <p id="info">地址 :浙江省杭州市西湖区教工路198号1幢3层339室</p>
            </li>
            <li>
              <p>关注我们</p>
              <div class="at_us">
                <div class="us_one us">
                  <img
                    src="../assets/image/QRcode/officialAccounts.jpg"
                    alt=""
                  />
                </div>
                <div class="us_two us">
                  <img src="../assets/image/QRcode/service.png" alt="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id="footer">
        <p>Copyright©艺阁书营 2023，All Rights Reserved</p>
        <a href="https://beian.miit.gov.cn/">浙ICP备19012181号</a>
        <a href="#">经营许可证编号：浙B2-20210107</a>
        <p class="jinghui">
          <img src="../assets/image/jinghui.d0289dc0.png" alt="" />
        </p>
        <p>浙公网安备 33010802011359号</p>
      </div>
    </div>
    <login @getShow="loginCh" :msg="Login" @userInfo="getUserInfo"></login>
  </div>
</template>
<script>
// import vueSeamlessScroll from "vue-seamless-scroll";
import Side from "../components/sidebar.vue";
import login from "../components/login.vue";
import WorkHeader from "../components/workHeader.vue";
// import { mapState } from "vuex";
export default {
  name: "Example03Basic",
  components: {
    Side,
    login,
    WorkHeader,
  },
  data() {
    return {
      Login: false,
      //用户数据初始化
      userInfo: null,
      token: "",
      num: 0,
      //
      isFixed: false,
      isFixedShow: false,
      showFunOne: false,
      showFunTwo: false,
      codeShow: false,
      value1: 5,
      listData2: [
        {
          user: "刘程",
          avatarUrl: require("../assets/image/userinfo_head/head_01.png"),
          userpost: "作家",
          eva: "2",
          eva_sp:
            "试用了一下，10秒就写出了我想要的小红书产品文案,感受到了强大的科技，仿佛打开了新世界",
        },
        {
          user: "何书恒",
          avatarUrl: require("../assets/image/userinfo_head/head_02.png"),
          userpost: "公务员",
          eva: "",
          eva_sp:
            "体验感很好，很有意思的软件，中文写作很强大，已经是我生活和工作中不可或缺的小助手了",
        },
        {
          user: "张敏",
          avatarUrl: require("../assets/image/userinfo_head/head_03.png"),
          userpost: "广告传媒",
          eva: "",
          eva_sp:
            "提升效率的工具，功能一应俱全，强烈推荐，模版全也比较专业，很实用的工具,输入几个关键词，就能完成其余工作，太高级了",
        },
        {
          user: "Mark",
          avatarUrl: require("../assets/image/userinfo_head/head_04.png"),
          userpost: "新媒体",
          eva: "",
          eva_sp:
            "真是不错的ai写作助手，模版全，内容多，我身边的完美军师,为我解决了很多创作上的瓶颈，提供了很多新的点子，很棒的功能",
        },
        {
          user: "李哲",
          avatarUrl: require("../assets/image/userinfo_head/head_05.png"),
          userpost: "记者",
          eva: "",
          eva_sp:
            "文字是经过加工润色的，灵感枯竭的时候提供了丰富思路，用起来事半功倍，很感激它能如此便利的提供内容，将我的写作时间减少了一半。",
        },
        {
          user: "庄邹言",
          avatarUrl: require("../assets/image/userinfo_head/head_06.png"),
          userpost: "资深运营",
          eva: "",
          eva_sp:
            "作为一个经常创作内容的人，为我节省了无数头脑风暴，还帮我将内容提升到一个新水平，体验感很好，能够代替很多重复性的工作",
        },
        {
          user: "桃花落",
          avatarUrl: require("../assets/image/userinfo_head/head_07.png"),
          userpost: "文案编辑",
          eva: "",
          eva_sp:
            "写作就选它了，这是一款真正的ai写作神器，你问它答居然还会接梗，挺有意思的玩意",
        },
      ],
      listData3: [
        {
          user: "聪耳",
          avatarUrl: require("../assets/image/userinfo_head/head_08.png"),
          userpost: "自媒体人",
          eva: "2",
          eva_sp:
            "强烈推荐!使用这个写作助手后写作面都得到了拓展，无论是商业文档、学术论文还是创意作品，它总能为我提供恰到好处的灵感和内容，让我可以更加高效的进行创作。",
        },
        {
          user: "Sakura",
          avatarUrl: require("../assets/image/userinfo_head/head_09.png"),
          userpost: "作词人",
          eva: "",
          eva_sp:
            "绝佳的AI写作软件!它的创新设计和强大功能令我惊喜。能够自动生成高质量的文本，不仅节省了我的时间，还提升了我的工作效率。",
        },
        {
          user: "翟恒",
          avatarUrl: require("../assets/image/userinfo_head/head_10.png"),
          userpost: "编剧",
          eva: "",
          eva_sp:
            "界面简洁友好，操作简单易懂，即使对于非技术人员也很容易上手。它的准确性和多样性也很完美，能够适应各种写作需求，并提供多种语言和风格选择。",
        },
        {
          user: "Blackl ",
          avatarUrl: require("../assets/image/userinfo_head/head_11.png"),
          userpost: "其他",
          eva: "",
          eva_sp:
            "这款AI写作软件是我找寻已久的利器，无论是商务文书还是创意写作，它都是我的得力助手!我对它的表现感到无比满意。",
        },
        {
          user: "plspace ",
          avatarUrl: require("../assets/image/userinfo_head/head_12.png"),
          userpost: "文案编辑",
          eva: "",
          eva_sp:
            "令人惊叹的AI写作软件！简单易用的界面，能够轻松地进行编辑和调整，节省了大量的时间和精力。对于那些寻求高效写作工具的人来说，这款AI写作软件绝对是首选！我由衷推荐它！",
        },
        {
          user: "徐昂",
          avatarUrl: require("../assets/image/userinfo_head/head_13.png"),
          userpost: "作家",
          eva: "",
          eva_sp:
            "这个AI写作软件真是太棒了！我用它写了几篇文章，读者都对我的写作能力赞不绝口。它的语言表达流畅自然，让我感觉就像是一个有天赋的作家。我非常满意这个软件带给我的帮助。",
        },
        {
          user: "Echo",
          avatarUrl: require("../assets/image/userinfo_head/head_14.png"),
          userpost: "自媒体人",
          eva: "",
          eva_sp:
            "我对这款AI写作软件赞不绝口！内容质量高，给我提供了一流的写作帮助，帮我省去了大量的时间和精力。如果你想提升写作效率和质量，不妨试试这个软件！",
        },
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
        step: 0.5,
      },
      classOption2: {
        limitMoveNum: 2,
        direction: 2,
        step: 0.5,
      },
      sideShow: false,
      screenWidth: document.body.clientWidth,
      //可视窗口宽度
      timer: null,
    };
  },
  created() {
    this.token = window.localStorage.getItem("token");
    this.userInfo = this.$store.state.userinfo.userInfo;
    this.$store.state.infoList.map((item) => {
      if (item.unread) {
        this.num++;
      }
    });
    this.Login = this.userInfo ? false : true;
    // console.log(this.userInfo);
  },
  computed: {
    //  this.$store.state.count
    // mapState       把全局  count 变成 可以直接使用的 数据
    // ...mapState(["userInfo"]),1
  },
  watch: {
    $route: {
      handler: function (Val) {
        // console.log(Val);
        if (Val.name == "Index") {
          let info = window.localStorage.getItem("token");
          if (!info) {
            // this.userInfo = null;
            this.Login = true;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
    window.addEventListener("resize", this.handleResize);
    //
    // this.init();
    //
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    //解决this指向问题，在window.addEventListener中this是指向window的。
    //需要将vue实例赋值给_this,这样在window.addEventListener中通过_this可以为vue实例上data中的变量赋值
    // let _this = this;
    //根据自己需要来监听对应的key
    // window.addEventListener("setItemEvent", function (e) {
    //   //e.key : 是值发生变化的key
    //   //例如 e.key==="token";
    //   //e.newValue : 是可以对应的新值
    //   // console.log("值改变了");
    //   if (e.key === "userInfo") {
    //     // console.log(e.newValue);
    //     _this.userInfo = JSON.parse(e.newValue);
    //   }
    // });
  },
  methods: {
    show_login() {
      this.Login = true;
      // console.log(this.Login + " 显示状态");
      // document.documentElement.style.overflowY = "hidden";
    },
    loginCh(val) {
      // console.log(val + "传输数据状态");
      if (!val) {
        this.Login = val;
        // document.documentElement.style.overflowY = "scroll";
      }
    },

    //登录窗口登陆成功后传值
    getUserInfo(val) {
      this.userInfo = { ...val.userInfo };
      // console.log(this.userInfo);
    },

    //
    // handleResize() {
    //   this.$nextTick(() => {
    //     this.timer = setTimeout(() => {
    //       // location.reload();
    //       this.$router.go(0);
    //     }, 500);
    //   });
    //   clearInterval(this.timer);
    // },

    //
    scrolling() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      // let scrollStep = scrollTop - this.oldScrollTop;
      if (scrollTop > 110) {
        this.isFixed = true;
        this.isFixedShow = true;
        // console.log("吸顶");
      } else {
        this.isFixed = false;
        this.isFixedShow = false;
      }
      if (scrollTop >= 800) {
        this.sideShow = true;
      } else {
        this.sideShow = false;
      }
    },

    app_leave() {
      this.codeShow = false;
    },
    app_enter() {
      this.codeShow = true;
    },

    //点击跳转
  },
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      this.Arr = [];
      // 把数据清空，可以稍微避免让用户看到之前缓存的数据
      // this.getData();
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false;
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false;
  },
};
</script>

