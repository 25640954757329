<template>
  <div class="test">
    <main>
      <div class="input">
        <input type="text" v-model="input" name="" id="" />
      </div>
      <button class="btn" @click="btn">发送</button>
      <div class="main" ref="main_com"></div>
    </main>

    <div class="test_upload">
      <div>
        <input type="file" @change="changeFile" ref="fileInput" />
        <button @click="upload()">上传</button>
      </div>
      <img :src="imageSrc" style="height: 100px; width: 100px" v-if="imageSrc" />
    </div>
  </div>
</template>
<script>
// import VueMarkdown from 'vue-markdown'
// import { get_dialogZhenhuan } from "../request/api";
import axios from "axios";
import { get_GPT, GetFAQContentById } from "../request/api";
export default {
  name: "App",
  components: {
    // VueMarkdown // 注入组件
  },
  data() {
    return {
      input: "",
      htmls: "",
      file: null,
      url: "http://47.103.40.97:8080/copyWriting/pc/getTextFromImage",
      imageSrc: "",
    };
  },
  methods: {
    upload() {
      // 上传图像通常要将文件参数放在fromdata 对象中
      // 创建formData实例
      let formData = new FormData();
      // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
      formData.append("file", this.file);
      console.log(formData); //这里输出的是一个空的formData对象，因为formData是加密处理的我们看不到其内容，但的的确确添加进去了。
      console.log(localStorage.getItem("token"));
      axios
        .post(this.url, formData, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          console.log(res);
        });
    },
    changeFile(e) {
      // 获取文件信息 e.target.files
      console.log(e.target.files[0]);
      this.file = e.target.files[0];

      const file = this.$refs.fileInput.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (temp) => {
        this.imageSrc = temp.target.result;
      };
      fileReader.readAsDataURL(file);
    },

    btn() {
      console.log(this.input);

      // let info = {
      //   text: this.input,
      //   nums: 1,
      //   // maxlen: 200,
      //   // minlen: 10,
      // };

      let info = {
        instruction: this.input,
        nums: 1,
        maxlen: 500,
        minlen: 10,
      };

      console.log(info);

      if (this.input) {
        get_GPT(info).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$refs.main_com.innerHTML += `<p style="text-align: left;margin: 0 0 5px 0;display: flex;line-height: 14px;">
          <span>我：</span>
          <span style="flex: 1;">${this.input}</span>
        </p>
        <p style="text-align: left;margin: 0 0 5px 0;display: flex;line-height: 14px;">
          <span> 角色:</span>
          <span style="flex: 1;">${res.result[0]}</span>
        </p>`;
          }
        });

        // get_dialogZhenhuan(info).then((res) => {
        //   this.$refs.main_com.innerHTML += `<p style="text-align: left;margin: 0 0 5px 0;display: flex;line-height: 14px;">
        //   <span>我：</span>
        //   <span style="flex: 1;">${this.input}</span>
        // </p>
        // <p style="text-align: left;margin: 0 0 5px 0;display: flex;line-height: 14px;">
        //   <span> 角色:</span>
        //   <span style="flex: 1;">${res.result[0]}</span>
        // </p>`;
        //   console.log(res);
        // });
      } else {
        this.$message({
          message: "文本不能为空",
          type: "none",
        });
      }

      console.log(this.$refs.main_com.innerHTML);
    },
  },
  created() {
    // let info =
    //   '## 列表\n在搜索数据库中的数据时，SQL通配符可以替代一个或多个字符。\n**SQL通配符必须与LIKE运算符一起使用。**\n\n在SQL中可以使用一下通配符：\n\n| 通配符                     | 描述                       |\n| :------------------------- | -------------------------- |\n| %                          | 代表零个或多个字符         |\n| _                          | 仅代表一个字符             |\n| [charlist]                 | 字符列中的任何单一字符     |\n| [^charlist]或者[!charlist] | 不在字符列中的任何单一字符 |\n\n## 删除线\n~这是一段带删除线的文字~\n\n## 有序列表\n- 测试1\n- 测试2\n- 测试3\n\n## 代码块\n```java\npublic class Main{\n  public static void main(String [] args){\n    System.out.println("hello Java");\n  }\n}\n```\n\n## 图片\n![alt](//cloundeasy-guangzhou.oss-cn-guangzhou.aliyuncs.com/2023-05-14/others/3372a575-adfc-4a71-ac79-4a152034a127.png)\n\n## 注脚\n\n一个具有注脚的文本。[^1]\n\n[^1]: 注脚的解释\n\n## 链接\n链接: [link](https://www.csdn.net/)\n\n';
    // // info = info.replace(/(\n|\r|\r\n|↵)/g, "<br/>");
    // this.html = info;

    GetFAQContentById("1689119337992998913").then((res) => {
      console.log(res);
    });
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.test {
  main {
    width: 300px;
    // height: 600px;
    margin: 100px auto;
    background-color: gray;
    box-sizing: border-box;
    padding: 20px 10px;

    div {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding: 5px 10px;
      input {
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 5px 10px;
      }
    }

    .btn {
      width: 100%;
      height: 25px;
      line-height: 25px;
      border-radius: 5px;
    }

    .main {
      height: auto;
      background-color: #fff;
      margin: 30px 0;
      box-sizing: border-box;
      padding: 10px;
      p {
        text-align: left;
        margin: 0 0 5px 0;
        display: flex;
        span {
          line-height: 14px;
        }
        span:last-child {
          flex: 1;
        }
      }
    }
  }

  .test_upload {
    width: 300px;
    height: 600px;
    margin: 100px auto;
    background-color: gray;
    box-sizing: border-box;
    padding: 20px 10px;
  }
}
</style>
