<template>
  <div class="propre">
    <div class="header">
      <div class="nav_header">
        <!-- <div class="left">
          <router-link :to="'/'"
            ><img src="../assets/image/index_nav-1(2).png" alt=""
          /></router-link>
        </div> -->
        <div class="mid">
          <div class="mid_left">
            <router-link :to="'/'"
              ><img src="../assets/image/index_nav-1(2).png" alt=""
            /></router-link>
          </div>
          <ul>
            <!-- <li>客户端下载</li> -->
            <router-link :to="'/livedemo'">
              <li>立即创作</li>
            </router-link>
            <router-link :to="'/productpresentation'">
              <li>产品介绍</li>
            </router-link>
            <li class="fun_business_cooperation">
              商务合作
              <div class="fun_one">
                <img src="../assets/image/QRcode/service.png" alt="" />
              </div>
            </li>
            <li class="fun_miniapp">
              在线体验
              <div class="fun_two">
                <img src="../assets/image/QRcode/applet.jpg" alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div class="right">
          <img
            v-if="!this.userInfo"
            src="../assets/image/index_nav-2.png"
            alt=""
            style="
              margin-top: 1rem;
              margin-right: 11.875rem;
              width: 5rem;
              height: 2.25rem;
            "
            @click="show_login"
          />
          <WorkHeader v-if="this.userInfo"></WorkHeader>
        </div>
      </div>
      <div class="title">
        <div class="title_p">
          <p class="p_tip">
            <img src="../assets/image/propreImage/title.png" alt="" />
          </p>
          <p class="p_sp">
            书营ai智能写作软件是艺阁集团专为新媒体写作者和各大企业量身定制的一站式辅助创作软件，输入关键词一键生成高质量文案内容，释放创意潜力，突破写作瓶颈，快速生成原创内容。旨在为大众开启智能媒体新应用，共同推动中文信息处理技术的进步。
          </p>
          <router-link :to="'/livedemo/livefunlist'">
            <p class="p_btn">立即体验</p>
          </router-link>
        </div>
        <div class="title_img"></div>
      </div>
    </div>
    <div class="core_fun">
      <div class="fun_title">核心功能</div>
      <div class="fun_list">
        <div>
          <img src="../assets/image/propreImage/2.5d_01.png" alt="" />
          <p>创作</p>
          <p class="p_sp">
            海量语料库、批量生成、篇幅长度可选、生成历史自动保存
          </p>
        </div>
        <div>
          <img src="../assets/image/propreImage/2.5d_02.png" alt="" />
          <p>生成</p>
          <p class="p_sp">原创文案、多种风格、可读性强、准确率高</p>
        </div>
      </div>
    </div>
    <div class="pro_fea">
      <div class="fea_title">产品特点</div>
      <div class="fea_list fea_list_one">
        <div>
          <img src="../assets/image/propreImage/icon_01.png" alt="" />
          <p>门槛低</p>
          <p class="p_sp">傻瓜式操作就能实现极致的搜索效果</p>
        </div>
        <div>
          <img src="../assets/image/propreImage/icon_02.png" alt="" />
          <p>效率高</p>
          <p class="p_sp">输入想法，一键生成，无限输出</p>
        </div>
        <div>
          <img src="../assets/image/propreImage/icon_03.png" alt="" />
          <p>成本少</p>
          <p class="p_sp">机器不用休息，一人能抵千军万马</p>
        </div>
      </div>
      <div class="fea_list fea_list_two">
        <div>
          <img src="../assets/image/propreImage/icon_05.png" alt="" />
          <p>个性化</p>
          <p class="p_sp">灵活定制场景，多种纬度输出内容</p>
        </div>
        <div>
          <img src="../assets/image/propreImage/icon_05(1).png" alt="" />
          <p>云存储</p>
          <p class="p_sp">多端同步，随时随地访问你的所有内容</p>
        </div>
        <div>
          <img src="../assets/image/propreImage/icon_06.png" alt="" />
          <p>历史库</p>
          <p class="p_sp">历史生成记录自动保存，随时恢复文档</p>
        </div>
      </div>
    </div>
    <div class="pro_sup">
      <p>产品优势</p>
      <div class="sup">
        <div class="sup_left">
          <img src="../assets/image/propreImage/image_02.png" alt="" />
        </div>
        <div class="sup_right">
          <div class="sup_list">
            <p>
              <img src="../assets/image/propreImage/get.png" alt="" />
              <span class="sup_sp">模型效果更佳</span>
            </p>
            <span id="sup_sp">模拟多个国内写作习惯，形成专有阅读模型</span>
          </div>
          <div class="sup_list">
            <p>
              <img src="../assets/image/propreImage/get.png" alt="" />
              <span class="sup_sp">海量语料库</span>
            </p>
            <span id="sup_sp">海量语料，聚合全网，创作更高效</span>
          </div>
          <div class="sup_list">
            <p>
              <img src="../assets/image/propreImage/get.png" alt="" />
              <span class="sup_sp">灵活性强</span>
            </p>
            <span id="sup_sp"
              >应用于众多场景和行业，还可根据客户需求定制部署</span
            >
          </div>
          <div class="sup_list">
            <p>
              <img src="../assets/image/propreImage/get.png" alt="" />
              <span class="sup_sp">快速响应</span>
            </p>
            <span id="sup_sp"
              >服务能够百毫秒内响应客户请求，内容生产高效省时</span
            >
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="pro_footer">
      <div class="top">
        <div class="left">
          <img src="../assets/image/index_footer-1.png" alt="" />
        </div>
        <div class="right">
          <ul>
            <li>关于我们</li>
            <router-link :to="'/helpcenter'">
              <li>帮助中心</li>
            </router-link>

            <li>
              <p>联系我们</p>
              <!-- <p>电话:</p> -->
              <p class="phone" id="info">电话 :0571-88055158</p>
              <!-- <p>邮箱:</p> -->
              <p class="eMail" id="info">邮箱 :Markting@ego1999.com</p>
              <!-- <p>地址:</p> -->
              <p id="info">地址 :浙江省杭州市西湖区教工路198号1幢3层339室</p>
            </li>
            <li>
              <p>关注我们</p>
              <div class="at_us">
                <div class="us_one us">
                  <img
                    src="../assets/image/QRcode/officialAccounts.jpg"
                    alt=""
                  />
                </div>
                <div class="us_two us">
                  <img src="../assets/image/QRcode/service.png" alt="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id="footer">
        <p>Copyright©艺阁书营 2023，All Rights Reserved</p> 
        <a href="https://beian.miit.gov.cn/">浙ICP备19012181号</a>
        <a href="#">经营许可证编号：浙B2-20210107</a>
        <p class="jinghui">
          <img src="../assets/image/jinghui.d0289dc0.png" alt="" />
        </p>
        <p>浙公网安备 33010802011359号</p>
      </div>
    </div>
    <!-- 登陸遮罩 -->
    <!-- <login @getShow="loginCh" :msg="Login"></login> -->
    <login @getShow="loginCh" :msg="Login" @userInfo="getUserInfo"></login>
  </div>
</template>
<script>
import login from "../components/login.vue";
import WorkHeader from "../components/workHeader.vue";
export default {
  components: {
    login,
    WorkHeader,
  },
  name: "App",
  data() {
    return {
      Login: false,
      userInfo: null,
      num: 0,
    };
  },
  methods: {
    show_login() {
      document.documentElement.style.overflowY = "hidden";
      this.Login = true;
    },
    loginCh(val) {
      if (!val) {
        this.Login = val;
        document.documentElement.style.overflowY = "scroll";
      }
    },
    //登录窗口登陆成功后传值
    getUserInfo(val) {
      this.userInfo = { ...val };
    },
  },
  created() {
    this.$store.state.infoList.map((item) => {
      if (item.unread) {
        this.num++;
      }
    });
    this.userInfo = this.$store.state.userinfo.userInfo;
    // console.log(this.userInfo);
  },
  mounted() {},
};
</script>
