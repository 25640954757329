<template>
  <div class="workbench">
    <div class="header">
      <div class="header_right">
        <router-link :to="'/'"
          ><img src="../assets/image/index_nav-1(2).png" alt=""
        /></router-link>
      </div>
      <div class="header_left">
        <WorkHeader></WorkHeader>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import WorkHeader from "../components/workHeader.vue";
export default {
  name: "App",
  components: {
    WorkHeader,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
  watch: {
  },
};
</script>
<style lang='scss' scoped>
</style>
