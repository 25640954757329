var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"propre"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"nav_header"},[_c('div',{staticClass:"mid"},[_c('div',{staticClass:"mid_left"},[_c('router-link',{attrs:{"to":'/'}},[_c('img',{attrs:{"src":require("../assets/image/index_nav-1(2).png"),"alt":""}})])],1),_c('ul',[_c('router-link',{attrs:{"to":'/livedemo'}},[_c('li',[_vm._v("立即创作")])]),_c('router-link',{attrs:{"to":'/productpresentation'}},[_c('li',[_vm._v("产品介绍")])]),_vm._m(0),_vm._m(1)],1)]),_c('div',{staticClass:"right"},[(!this.userInfo)?_c('img',{staticStyle:{"margin-top":"1rem","margin-right":"11.875rem","width":"5rem","height":"2.25rem"},attrs:{"src":require("../assets/image/index_nav-2.png"),"alt":""},on:{"click":_vm.show_login}}):_vm._e(),(this.userInfo)?_c('WorkHeader'):_vm._e()],1)]),_c('div',{staticClass:"title"},[_c('div',{staticClass:"title_p"},[_vm._m(2),_c('p',{staticClass:"p_sp"},[_vm._v(" 书营ai智能写作软件是艺阁集团专为新媒体写作者和各大企业量身定制的一站式辅助创作软件，输入关键词一键生成高质量文案内容，释放创意潜力，突破写作瓶颈，快速生成原创内容。旨在为大众开启智能媒体新应用，共同推动中文信息处理技术的进步。 ")]),_c('router-link',{attrs:{"to":'/livedemo/livefunlist'}},[_c('p',{staticClass:"p_btn"},[_vm._v("立即体验")])])],1),_c('div',{staticClass:"title_img"})])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pro_footer"},[_c('div',{staticClass:"top"},[_vm._m(6),_c('div',{staticClass:"right"},[_c('ul',[_c('li',[_vm._v("关于我们")]),_c('router-link',{attrs:{"to":'/helpcenter'}},[_c('li',[_vm._v("帮助中心")])]),_vm._m(7),_vm._m(8)],1)])]),_vm._m(9)]),_c('login',{attrs:{"msg":_vm.Login},on:{"getShow":_vm.loginCh,"userInfo":_vm.getUserInfo}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fun_business_cooperation"},[_vm._v(" 商务合作 "),_c('div',{staticClass:"fun_one"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/service.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fun_miniapp"},[_vm._v(" 在线体验 "),_c('div',{staticClass:"fun_two"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/applet.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p_tip"},[_c('img',{attrs:{"src":require("../assets/image/propreImage/title.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"core_fun"},[_c('div',{staticClass:"fun_title"},[_vm._v("核心功能")]),_c('div',{staticClass:"fun_list"},[_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/2.5d_01.png"),"alt":""}}),_c('p',[_vm._v("创作")]),_c('p',{staticClass:"p_sp"},[_vm._v(" 海量语料库、批量生成、篇幅长度可选、生成历史自动保存 ")])]),_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/2.5d_02.png"),"alt":""}}),_c('p',[_vm._v("生成")]),_c('p',{staticClass:"p_sp"},[_vm._v("原创文案、多种风格、可读性强、准确率高")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_fea"},[_c('div',{staticClass:"fea_title"},[_vm._v("产品特点")]),_c('div',{staticClass:"fea_list fea_list_one"},[_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_01.png"),"alt":""}}),_c('p',[_vm._v("门槛低")]),_c('p',{staticClass:"p_sp"},[_vm._v("傻瓜式操作就能实现极致的搜索效果")])]),_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_02.png"),"alt":""}}),_c('p',[_vm._v("效率高")]),_c('p',{staticClass:"p_sp"},[_vm._v("输入想法，一键生成，无限输出")])]),_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_03.png"),"alt":""}}),_c('p',[_vm._v("成本少")]),_c('p',{staticClass:"p_sp"},[_vm._v("机器不用休息，一人能抵千军万马")])])]),_c('div',{staticClass:"fea_list fea_list_two"},[_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_05.png"),"alt":""}}),_c('p',[_vm._v("个性化")]),_c('p',{staticClass:"p_sp"},[_vm._v("灵活定制场景，多种纬度输出内容")])]),_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_05(1).png"),"alt":""}}),_c('p',[_vm._v("云存储")]),_c('p',{staticClass:"p_sp"},[_vm._v("多端同步，随时随地访问你的所有内容")])]),_c('div',[_c('img',{attrs:{"src":require("../assets/image/propreImage/icon_06.png"),"alt":""}}),_c('p',[_vm._v("历史库")]),_c('p',{staticClass:"p_sp"},[_vm._v("历史生成记录自动保存，随时恢复文档")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_sup"},[_c('p',[_vm._v("产品优势")]),_c('div',{staticClass:"sup"},[_c('div',{staticClass:"sup_left"},[_c('img',{attrs:{"src":require("../assets/image/propreImage/image_02.png"),"alt":""}})]),_c('div',{staticClass:"sup_right"},[_c('div',{staticClass:"sup_list"},[_c('p',[_c('img',{attrs:{"src":require("../assets/image/propreImage/get.png"),"alt":""}}),_c('span',{staticClass:"sup_sp"},[_vm._v("模型效果更佳")])]),_c('span',{attrs:{"id":"sup_sp"}},[_vm._v("模拟多个国内写作习惯，形成专有阅读模型")])]),_c('div',{staticClass:"sup_list"},[_c('p',[_c('img',{attrs:{"src":require("../assets/image/propreImage/get.png"),"alt":""}}),_c('span',{staticClass:"sup_sp"},[_vm._v("海量语料库")])]),_c('span',{attrs:{"id":"sup_sp"}},[_vm._v("海量语料，聚合全网，创作更高效")])]),_c('div',{staticClass:"sup_list"},[_c('p',[_c('img',{attrs:{"src":require("../assets/image/propreImage/get.png"),"alt":""}}),_c('span',{staticClass:"sup_sp"},[_vm._v("灵活性强")])]),_c('span',{attrs:{"id":"sup_sp"}},[_vm._v("应用于众多场景和行业，还可根据客户需求定制部署")])]),_c('div',{staticClass:"sup_list"},[_c('p',[_c('img',{attrs:{"src":require("../assets/image/propreImage/get.png"),"alt":""}}),_c('span',{staticClass:"sup_sp"},[_vm._v("快速响应")])]),_c('span',{attrs:{"id":"sup_sp"}},[_vm._v("服务能够百毫秒内响应客户请求，内容生产高效省时")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("../assets/image/index_footer-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("联系我们")]),_c('p',{staticClass:"phone",attrs:{"id":"info"}},[_vm._v("电话 :0571-88055158")]),_c('p',{staticClass:"eMail",attrs:{"id":"info"}},[_vm._v("邮箱 :Markting@ego1999.com")]),_c('p',{attrs:{"id":"info"}},[_vm._v("地址 :浙江省杭州市西湖区教工路198号1幢3层339室")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("关注我们")]),_c('div',{staticClass:"at_us"},[_c('div',{staticClass:"us_one us"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/officialAccounts.jpg"),"alt":""}})]),_c('div',{staticClass:"us_two us"},[_c('img',{attrs:{"src":require("../assets/image/QRcode/service.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer"}},[_c('p',[_vm._v("Copyright©艺阁书营 2023，All Rights Reserved")]),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("浙ICP备19012181号")]),_c('a',{attrs:{"href":"#"}},[_vm._v("经营许可证编号：浙B2-20210107")]),_c('p',{staticClass:"jinghui"},[_c('img',{attrs:{"src":require("../assets/image/jinghui.d0289dc0.png"),"alt":""}})]),_c('p',[_vm._v("浙公网安备 33010802011359号")])])
}]

export { render, staticRenderFns }